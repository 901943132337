@use '../../../../../styles/mixins' as mixins;

.loader {
  @include mixins.flex-column(initial, flex-start);

  gap: 1rem;
  padding: 1rem;
  width: calc(100% - 2rem);

  &--stats {
    @include mixins.flex-row(center, flex-start);

    gap: 1rem;
  }
}
