@use '../../../styles/mixins' as mixins;

.user-mention {
  @include mixins.flex-column(initial, flex-start);

  gap: 1rem;
  padding-bottom: 18rem;

  &__title {
    @include mixins.font-style(1.25rem, #43445b, 600);
  }

  &__name {
    @include mixins.font-style(1rem, #1e1e1e);
    @include mixins.ellipsis(calc(100% - 4rem));

    line-height: 1.25rem;
  }

  &__buttons {
    @include mixins.flex-row(center, flex-end);

    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
    gap: 1rem;
  }
}
