@use '../../../styles/mixins' as mixins;

.color-picker {
  @include mixins.flex-row(center, flex-start);
  @include mixins.font-style(0.875rem, #43445b);

  gap: 0.5rem;
  line-height: 1rem;
  cursor: pointer;

  &__input {
    border: 0;
    cursor: pointer;
  }
}
