@use '../../../../styles/mixins' as mixins;

.card {
  @include mixins.flex-column(initial, flex-start);

  gap: 1.5rem;

  &__header {
    @include mixins.flex-row(initial, flex-start);

    gap: 0.75rem;

    &-author {
      @include mixins.flex-column(initial, space-between);
    }
  }

  &__content {
    @include mixins.flex-column(initial, flex-start);

    gap: 1.5rem;

    &-text {
      @include mixins.flex-column(initial, flex-start);

      gap: 0.75rem;
    }
  }
}
