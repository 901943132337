@use '../../../../styles/mixins' as mixins;

$prefix: '--notifications';

.notifications {
  width: 28rem;
  padding: 1rem;
  background-color: var(#{$prefix}-background-color);

  &--mobile {
    width: 20rem;
  }

  &__icon--has-new::after {
    position: absolute;
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    right: 0.0625rem;
    top: 0;
    background: var(#{$prefix}-icon-indicator-color);
    border-radius: 50%;
  }

  &__header {
    @include mixins.flex-row(center, flex-start);

    &-title {
      @include mixins.font-style(1.25rem, var(#{$prefix}-title-color), 600);

      line-height: 1.75rem;
    }
  }

  &__infinite-scroll {
    @include mixins.custom-scrollbar();
  }

  &__loader {
    height: 5rem;
    margin-top: 1rem;
  }

  &__group-label {
    @include mixins.font-style(0.875rem, var(#{$prefix}-primary-text-color), 600);

    margin: 1rem 1rem 0 1rem;
    line-height: 1.25rem;
  }

  &__no-content {
    @include mixins.font-style(1rem, var(#{$prefix}-secondary-text-color));

    text-align: center;
    margin: 2.38rem 1rem;
    line-height: 1.25rem;
  }
}
