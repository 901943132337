@use '../../../styles/mixins' as mixins;

$prefix: '--profile';
$avatar-prefix: '--profile-avatar';
$upload-prefix: '--profile-upload';

.edit-profile {
  @include mixins.flex-column();

  position: relative;
  padding: 4.125rem 1rem 1rem;
  border: 0.0625rem solid var(#{$prefix}-border-color);
  background: linear-gradient(
    to bottom,
    var(#{$prefix}-head-background-color) 9.375rem,
    white 9.4375rem,
    white 100%
  );

  &__avatar-upload {
    @include mixins.flex-column();
  }

  &__avatar-wrapper {
    background: var(#{$avatar-prefix}-border-color);
    border: 0.25rem solid var(#{$avatar-prefix}-border-color);
    transition: border-color 0.3s;

    &:hover {
      background: var(#{$avatar-prefix}-border-hover-color);
      border-color: var(#{$avatar-prefix}-border-hover-color);
    }
  }

  &__fields-wrapper {
    @include mixins.flex-column(center, flex-end);

    width: 100%;
    margin-top: 1.5rem;
    gap: 1.5rem;
  }

  &__buttons-wrapper {
    @include mixins.flex-row(center, flex-end);

    width: 100%;
    margin-top: 1.5rem;
    gap: 1rem;
  }
}
