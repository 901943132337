@use '../../../styles/mixins' as mixins;

$prefix: '--card-social';

.preview-card-social {
  @include mixins.flex-column(flex-start, flex-start);

  width: 100%;
  gap: 0.125rem;

  &__source {
    @include mixins.flex-row(flex-start, flex-start);

    width: 100%;
  }

  &__person {
    @include mixins.font-style(0.75rem, var(#{$prefix}-text-color), 600);
    @include mixins.ellipsis(calc(100% - 0.875rem - 0.25rem)); //100% - icon width - gap

    line-height: 1.125rem;
    margin-left: 0.25rem;
  }

  &__quote {
    @include mixins.font-style(0.875rem, var(#{$prefix}-text-color));

    width: 100%;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    line-height: 1.25rem;
  }
}
