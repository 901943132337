@use '../../../../../../styles/mixins' as mixins;

.content {
  @include mixins.flex-row(center, flex-start);

  padding: 1rem;
  border: 0.0625rem solid #ebebeb;
  border-top-width: 0;

  &__file {
    display: none;
  }

  &__button {
    margin: 0 auto;
  }
}
