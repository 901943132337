@use '../../../../styles/mixins' as mixins;

.link-post {
  @include mixins.flex-column(flex-start);

  gap: 0.5rem;

  &__input,
  &__loader {
    width: calc(100% - 2rem);
    padding: 1rem;
  }
}
