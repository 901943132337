@use '../../../styles/mixins' as mixins;

.notifications-right-side {
  @include mixins.flex-column(initial, flex-start);

  position: sticky;
  width: 16.75rem;
  top: 6rem;
  padding: 1rem;
  gap: 1rem;
  // background-color: #f8f8f8;
  background-color: #fff;

  &__stats {
    @include mixins.flex-column(initial);

    gap: 0.5rem;

    &-title {
      @include mixins.font-style(1.25rem, #43445b, 600);

      line-height: 2.25rem;
    }

    &-item {
      @include mixins.flex-row(center, flex-start);

      &-label {
        @include mixins.font-style(0.875rem, #888b90);

        line-height: 1.25rem;
      }

      &-data {
        @include mixins.flex-row(center, flex-start);

        gap: 0.2rem;
        margin-left: auto;
      }

      &-value {
        @include mixins.font-style(1.25rem, #43445b, 600);

        line-height: 1.5rem;
      }
    }
  }

  &__info {
    position: absolute;
    right: 1rem;
    top: 1.4rem;
  }
}
