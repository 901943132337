@use '../../../../../styles/mixins' as mixins;

$prefix: '--analytics-charts-table';
$link-prefix: '--base-link';

.table {
  width: 100%;
  border-collapse: collapse;
  overflow: scroll;

  &__wrapper {
    @include mixins.custom-scrollbar();
    @include mixins.custom-scrollbar(horizontal);

    max-height: 37rem;
    overflow-x: auto;
    white-space: nowrap;

    &--loading {
      pointer-events: none;
    }
  }

  &__thead {
    @include mixins.font-style(0.875rem, var(#{$prefix}-header-text-color));

    text-align: left;
  }

  &__tbody--loading {
    opacity: 0.3;
  }

  &__th,
  &__td {
    padding: 0.5rem 1rem;
  }

  &__th {
    position: sticky;
    top: 0;
    background-color: var(#{$prefix}-header-background-color);
    z-index: 1;
    cursor: default;

    &-arrow {
      position: absolute;
      top: 50%;
      margin-top: -0.55rem;
      right: 0;
      width: 1rem;
      height: 1rem;

      &--up {
        @include mixins.transform(rotate(180deg));
      }
    }

    &--sub-header {
      top: 2rem;
    }

    &--order-by {
      cursor: pointer;
    }
  }

  &__td {
    background-color: #fff;

    &--link {
      @include mixins.ellipsis(10rem);

      color: var(#{$link-prefix}-text-color);
      cursor: pointer;

      &:hover {
        color: var(#{$link-prefix}-text-hover-color);
      }
    }
  }

  &--sticky {
    .table__th--header:first-child,
    .table__td:first-child {
      @include mixins.ellipsis(18rem);

      position: sticky;
      left: 0;

      &:after {
        position: absolute;
        content: '';
        width: 0.0625rem;
        height: 100%;
        right: 0;
        top: 0;
        background: #ebebeb;
      }
    }

    .table__th--header:first-child {
      z-index: 2;
    }
  }

  &__tr:hover .table__td {
    background-color: #f9f9fa;
  }

  &__no-records {
    padding: 2rem;
    text-align: center;
  }

  &__pagination {
    @include mixins.flex-row(center, flex-end);

    border-top: 0.0625rem solid #f0f6ff;
    padding: 0.5rem 1rem;
    gap: 1rem;

    &__label {
      @include mixins.font-style(1rem, #1e1e1e);
    }
  }
}
