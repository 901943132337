@use '../../../../../styles/mixins' as mixins;

$prefix: '--input';

.gallery {
  @include mixins.flex-column(flex-start, flex-start);

  width: 100%;
  gap: 1rem;

  &-wrapper {
    @include mixins.flex-column();

    gap: 1rem;
  }

  &__input {
    display: none;
  }

  &__caption {
    position: relative;
    width: 100%;

    &-title {
      @include mixins.font-style(1rem, #43445b, 600);
    }

    &-input {
      @include mixins.font-style(1rem, var(#{$prefix}-text-color));

      border-radius: 0.25rem;
      border: 0.0625rem solid;
      border-color: var(#{$prefix}-border-color);
      line-height: 1.5rem;
      padding: 0.75rem 2rem 0.75rem 1rem;
      width: calc(100% - 3rem);
      outline: 0;

      &:focus,
      &:active {
        background-color: var(#{$prefix}-background-focus-color);
        color: var(#{$prefix}-text-focus-color);
        border-color: var(#{$prefix}-border-focus-color);
      }
    }
  }

  &__preview-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 0.0625rem;
  }

  &__preview {
    position: relative;
    width: calc((100% - 0.25rem) / 4);
    height: 10rem;

    &--move {
      cursor: move;
    }

    &--ghost {
      opacity: 0.35;
    }

    &--dragging *,
    &--loading {
      pointer-events: none;
    }

    &-img {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      transition: background-image 1s ease-in-out; //hack to prevent flickering
    }

    &-actions {
      @include mixins.flex-row(flex-start, flex-start);

      gap: 0.5rem;
      position: absolute;
      right: 0.625rem;
      top: 0.625rem;

      &-remove,
      &-settings {
        position: relative;
        width: 2rem;
        height: 2rem;
        background: #fff;
        border: 0.0625rem solid #ebebeb;
        border-radius: 50%;
        box-sizing: border-box;
        box-shadow: 0 0 0.2rem #293033;
        opacity: 0.5;
        transition: opacity 0.3s;
        cursor: pointer;

        &--active,
        &:hover {
          opacity: 1;
        }
      }

      &-remove::before,
      &-remove::after {
        @include mixins.transform(rotate(45deg));

        position: absolute;
        content: '';
        left: 0.9rem;
        top: 0.35rem;
        height: 1.2rem;
        width: 0.1rem;
        background-color: #787878;
      }

      &-remove:after {
        @include mixins.transform(rotate(-45deg));
      }
    }
  }
}
