@use '../../../styles/mixins' as mixins;

$prefix: '--filter-badge';

.badge {
  @include mixins.font-style(0.875rem, var(#{$prefix}-text-color));
  @include mixins.flex-row(center, flex-start);

  width: fit-content;
  gap: 0.5rem;
  position: relative;
  padding: 0.5rem;
  border-radius: 0.25rem;
  line-height: 1.25rem;
  background: var(#{$prefix}-background-color);
  word-break: break-word;
  white-space: pre-wrap;
  cursor: default;
}
