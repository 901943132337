@use '../../../../styles/mixins' as mixins;

.story-sync {
  @include mixins.flex-column(initial, flex-start);

  gap: 1rem;

  &__story {
    max-width: calc(100% - 15rem);

    &--full-width {
      max-width: unset;
    }

    &-wrapper {
      @include mixins.flex-row(flex-start, center);

      gap: 1rem;
    }

    &-last-items {
      max-width: 11rem;
    }

    &-copy-from-source {
      max-width: max-content;
    }
  }
}
