@use '../../../styles/mixins' as mixins;

$prefix: '--input';

.input-field {
  position: relative;
  width: 100%;

  &__label {
    @include mixins.font-style(0.75rem, var(#{$prefix}-label-color));

    display: block;
    margin-bottom: 0.25rem;
    line-height: 1rem;

    &--clickable {
      cursor: pointer;
    }
  }

  &__value {
    @include mixins.font-style(1rem, var(#{$prefix}-text-color));

    border-radius: 0.25rem;
    border: 0.0625rem solid;
    border-color: var(#{$prefix}-border-color);
    line-height: 1.5rem;
    padding: 0.75rem 3rem 0.75rem 1rem;
    width: calc(100% - 4rem - 0.125rem);

    &:focus,
    &:active {
      background-color: var(#{$prefix}-background-focus-color);
      color: var(#{$prefix}-text-focus-color);
      border-color: var(#{$prefix}-border-focus-color);
    }

    &:disabled {
      background-color: var(#{$prefix}-background-disabled-color);
      color: var(#{$prefix}-text-disabled-color);
      border-color: var(#{$prefix}-border-disabled-color);
    }

    &--number {
      padding-right: 1rem;
      width: calc(100% - 2rem - 0.125rem);
    }

    &--error {
      background-color: var(#{$prefix}-background-error-color);
      color: var(#{$prefix}-text-error-color);
      border-color: var(#{$prefix}-border-error-color);

      &:focus {
        border-color: var(#{$prefix}-border-error-color);
      }
    }

    &--borderless {
      border: 0;
    }
  }

  &__icon {
    position: absolute;
    right: 1rem;
    top: 2.25rem;

    &--without-label {
      top: 1rem;
    }
  }

  &__error-message {
    @include mixins.font-style(0.75rem, var(#{$prefix}-error-message-color));

    margin-top: 0.25rem;
  }
}
