@use '../../../../styles/mixins' as mixins;

.image {
  &__wrapper {
    @include mixins.flex-row();

    position: relative;

    &--full-size {
      width: 100%;
    }

    &--clickable {
      cursor: pointer;
    }
  }

  &__preview {
    max-width: 100%;
    max-height: 40rem;
    user-select: none;

    &--full-size {
      width: 100%;
      max-height: unset;
    }
  }

  &__caption {
    @include mixins.font-style(0.875rem, #98a1a6);

    margin-top: 0.5rem;
    line-height: 1.5rem;
    word-break: break-word;
    white-space: pre-wrap;
  }

  &__modal-preview {
    max-width: 100%;
    max-height: calc(100vh - 0.25rem);
  }
}
