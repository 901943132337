@use '../../../styles/mixins' as mixins;

$prefix: '--card-content';

.preview-card-content {
  @include mixins.flex-column(flex-start, flex-start);

  width: 100%;
  gap: 0.125rem;

  &__source,
  &__title,
  &__subTitle,
  &__text {
    width: 100%;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    line-height: 1.25rem;
  }

  &__source {
    @include mixins.font-style(0.75rem, var(#{$prefix}-source-color), 600);
    @include mixins.ellipsis();
  }

  &__title {
    @include mixins.font-style(0.875rem, var(#{$prefix}-headline-color), 600);
  }

  &__subTitle {
    @include mixins.font-style(0.875rem, var(#{$prefix}-headline-color));
  }

  &__text {
    @include mixins.font-style(0.875rem, var(#{$prefix}-text-color));

    a {
      text-decoration: none;
      color: var(#{$prefix}-text-color);
    }

    //needed to simulate line breaks
    //same like line-height, needed for LineClamp component to calculate rows properly
    p:not(:first-child) {
      margin-top: 1.25rem;
    }
  }

  &__read-more {
    position: relative;

    color: var(#{$prefix}-headline-color);

    &-text {
      color: var(#{$prefix}-text-color);
    }
  }

  &__title-icon {
    display: inline-flex;
    position: relative;
    top: 0.15rem;
  }

  &__read-more-icon {
    position: absolute;
    top: 0.25rem;
    left: 1rem;
  }
}
