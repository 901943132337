@use '../../../../styles/mixins' as mixins;

$feed-prefix: '--horizontal-feed';
$card-prefix: '--card';

.card {
  @include mixins.flex-column(flex-start, space-between);

  width: calc(
    (var(--content-max-width) - 2 * var(#{$feed-prefix}-space-between-cards)) /
      var(#{$feed-prefix}-cards-per-view)
  );
  height: 20rem;
  border: 0.0625rem solid var(#{$card-prefix}-border-color);
  box-sizing: border-box;
  background-color: var(#{$card-prefix}-background-color);
  transition: box-shadow 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;

  &:hover {
    box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.2);
  }

  &--collapsed {
    height: 9rem;
  }

  &__teaser {
    position: relative;
    height: 7rem;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;

    &-icon {
      @include mixins.position-centered();
    }

    &--overlay {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(#{$feed-prefix}-overlay-background-color);
      }
    }

    &--placeholder {
      background: var(#{$feed-prefix}-placeholder-background-color);

      &:after,
      &:before {
        @include mixins.position-centered();

        content: '';
        border-radius: 50%;
      }

      &:before {
        height: 7.5rem;
        width: 7.5rem;
        border: 1.625rem solid var(#{$feed-prefix}-placeholder-color);
      }

      &:after {
        width: 4rem;
        height: 4rem;
        background: var(#{$feed-prefix}-placeholder-color);
      }
    }
  }

  &__content {
    @include mixins.flex-column(flex-start, space-between);

    gap: 0.5rem;
    width: calc(100% - 1rem);
    height: 12rem; //card height - teaser height - gap
    padding: 0.5rem;

    &--collapsed {
      height: 1rem;
    }
  }

  &__posted-time {
    @include mixins.font-style(0.75rem, var(#{$card-prefix}-posted-time-color));

    line-height: 1.125rem;
  }

  &__tags {
    position: absolute;
    bottom: 0.6rem;
    right: 2.5rem;
  }
}
