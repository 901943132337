@use '../../../styles/mixins' as mixins;

$prefix: '--card';

.author-time-sync {
  @include mixins.flex-row(center, flex-start);

  gap: 0.5rem;
  width: 100%;

  &__created-time {
    @include mixins.font-style(0.875rem, var(#{$prefix}-posted-time-color));

    line-height: 1.25rem;
    flex-shrink: 0;
  }
}
