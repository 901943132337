@use '../../../../styles/mixins' as mixins;

.tags {
  @include mixins.flex-row(center, flex-start);

  flex-wrap: wrap;
  gap: 0.5rem;

  &__item {
    word-break: break-word;
    white-space: pre-wrap;
  }
}
