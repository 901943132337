@use '../../../../styles/mixins' as mixins;

$prefix: '--create-card';

.create-card {
  @include mixins.flex-column(flex-start, flex-start);

  gap: 1rem;
  width: 100%;

  &__title {
    @include mixins.font-style(1.25rem, var(#{$prefix}-title-color), 600);

    line-height: 1.75rem;
  }

  &__content {
    border: 0.125rem solid #ebebeb;
    border-top: 0;
  }
}
