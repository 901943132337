@use '../../../styles/mixins' as mixins;

.counter {
  @include mixins.flex-row(center, flex-start);

  gap: 0.5rem;
  height: 2.5rem;

  &--clickable {
    cursor: pointer;

    & .counter__reactions-icon-count {
      cursor: pointer;
    }
  }

  &__reactions {
    @include mixins.flex-row();

    &-icon {
      @include mixins.flex-row();

      gap: 0.125rem;
      position: relative;
      margin-right: -0.625rem;
      height: 1.875rem;
      border-radius: 50%;

      &--individual {
        margin-right: 0.5rem;
      }

      &:last-child {
        margin-right: 0;
      }

      &-count {
        @include mixins.font-style(0.875rem, #6d767c);

        cursor: default;

        &--mine {
          font-weight: 600;
        }
      }
    }
  }

  &__label {
    @include mixins.font-style(0.875rem, #6d767c);

    cursor: pointer;
  }
}
