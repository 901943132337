@use '../../../styles/mixins' as mixins;

.comments {
  @include mixins.flex-column(initial);

  gap: 1rem;
  width: 100%;
  padding-top: 1rem;

  &__list {
    @include mixins.flex-column(initial);

    gap: 1rem;

    &-comment,
    &-reply {
      @include mixins.flex-column(initial);

      gap: 1rem;
    }

    &-reply,
    &-reply-active {
      margin-left: 2rem;
    }
  }

  &__view-more-comments {
    margin-left: 3.25rem;
  }

  &__view-more-replies {
    margin-left: 5.25rem;
  }
}
