.card {
  position: relative;
  border: 0.0625rem solid transparent;
  transition: background-color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

  &__drag-icon {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 50%;
    margin-left: -0.4375rem;
    transition: opacity 0.35s;
    z-index: 2;
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;

    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }

  &:hover .card__drag-icon {
    opacity: 1;
  }

  &--dragging {
    background-color: #f3f3f3;

    :global(.swiper-wrapper) {
      display: block;
    }
  }

  &--dragging * {
    pointer-events: none;
  }

  &--ghost {
    opacity: 0.5;
    border-color: #488ed8;
  }
}
