@use '../../../../styles/mixins' as mixins;

$prefix: '--card-content';
$link-prefix: '--base-link';

.card-content {
  @include mixins.flex-row(flex-start, space-between);

  gap: 1rem;

  &__fields {
    @include mixins.flex-column(flex-start, flex-start);

    gap: 0.5rem;
    width: 100%;
    max-width: 100%;

    &--with-side-teaser {
      max-width: calc(100% - 9.375rem - 1rem); //width - teaser - gap
    }

    &-source,
    &-title,
    &-subTitle,
    &-text {
      width: 100%;
      overflow-wrap: break-word;
      white-space: pre-wrap;
    }

    &-source {
      @include mixins.font-style(0.875rem, var(#{$prefix}-source-color), 600);
      @include mixins.ellipsis();

      line-height: 1.25rem;

      &-wrapper {
        @include mixins.flex-row(center, flex-start);

        width: 100%;
        gap: 0.5rem;
      }
    }

    &-title {
      @include mixins.font-style(1.5rem, var(#{$prefix}-headline-color), 600);

      width: 100%;
      overflow-wrap: break-word;
      white-space: pre-wrap;
      line-height: 2rem;

      &-icon {
        display: inline-flex;
        position: relative;
        top: 0.25rem;
        left: 0.25rem;
      }
    }

    &-media {
      margin: 0 calc(-1 * var(--card-spacing));
      width: calc(100% + 2 * var(--card-spacing));
    }

    &-subTitle {
      @include mixins.font-style(1.25rem, var(#{$prefix}-sub-headline-color));

      line-height: 1.75rem;

      &--clickable {
        cursor: pointer;
      }
    }

    &-text {
      @include mixins.font-style(1rem, var(#{$prefix}-text-color));

      line-height: 1.5rem;

      a {
        text-decoration: none;
        color: var(#{$link-prefix}-text-color);

        &:hover {
          color: var(#{$link-prefix}-text-hover-color);
        }
      }

      //same like line-height, needed for LineClamp component to calculate rows properly
      p:not(:first-child) {
        margin-top: 1.5rem;
      }

      &--medium {
        font-size: 1.25rem;
        line-height: 1.75rem;

        p:not(:first-child) {
          margin-top: 1.75rem;
        }
      }

      &--large {
        font-size: 1.5rem;
        line-height: 2rem;

        p:not(:first-child) {
          margin-top: 2rem;
        }
      }
    }
  }

  &__teaser {
    position: relative;
    width: 9.375rem;
    height: 9.375rem;
    background-size: cover;
    background-position: center;
  }

  &__read-more {
    @include mixins.font-style(1rem, var(#{$link-prefix}-text-color), 600);

    line-height: 1.5rem;
    cursor: pointer;

    &:hover {
      color: var(#{$link-prefix}-text-hover-color);
    }
  }

  &--chat .card-content__fields {
    &-title {
      font-size: 1.25rem;
      line-height: 1.25rem;
    }

    &-subTitle {
      font-size: 1rem;
      line-height: 1.25rem;
    }

    &-text {
      font-size: 0.875rem;
      line-height: 1rem;
    }
  }
}
