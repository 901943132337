@use '../../../styles/mixins' as mixins;

.permission {
  @include mixins.flex-column(flex-start);

  gap: 0.75rem;

  &__title {
    @include mixins.font-style(1.5rem, #43445b, 600);
  }

  &__intro {
    @include mixins.font-style(0.875rem, #787878);
  }

  &__buttons {
    @include mixins.flex-row();

    width: 100%;
    gap: 1rem;
  }
}
