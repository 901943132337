@use '../../../../../../styles/mixins' as mixins;

$prefix: '--media-file';

.preview {
  width: calc(100% - 2rem);
  padding: 1rem;
  background-color: var(#{$prefix}-background-color);
  cursor: pointer;
}
