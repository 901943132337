@use '../../../../../styles/mixins' as mixins;

$prefix: '--create-card-teaser';

.teaser-style {
  @include mixins.flex-column(flex-start, flex-start);

  width: 100%;
  gap: 0.25rem;

  &__title {
    @include mixins.font-style(0.75rem, var(#{$prefix}-title-color));

    line-height: 1.25rem;
  }

  &__item {
    @include mixins.flex-row(center, flex-start);

    gap: 1rem;
    padding: 0.25rem 0.75rem;
    width: 100%;
    border: 0.0625rem solid #ebebeb;
    box-sizing: border-box;
    border-radius: 0.5rem;
    cursor: pointer;

    &:hover {
      border-color: var(#{$prefix}-item-active-color);
    }

    &--selected {
      border-color: var(#{$prefix}-item-active-color);
      background-color: var(#{$prefix}-background-active-color);

      .teaser-style__item-radio::after {
        @include mixins.position-centered();

        content: '';
        width: 0.625rem;
        height: 0.625rem;
        background-color: var(#{$prefix}-item-active-color);
        border-radius: 50%;
      }
    }

    &-radio {
      position: relative;
      width: 1.125rem;
      height: 1.125rem;
      border-radius: 50%;
      border: 0.0625rem solid #b3b3b3;
      box-sizing: border-box;
    }
  }
}
