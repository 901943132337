@use '../../../styles/mixins' as mixins;

.panel {
  position: absolute;
  left: 0;
  width: 100%;
  top: 4.5rem;
  pointer-events: none;

  &--open {
    pointer-events: unset;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #23232e80;
    left: -100%;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &__content {
    @include mixins.custom-scrollbar();

    position: relative;
    width: var(--left-sidebar-width);
    left: -100%;
    padding: 1rem;
    height: calc(100dvh - 6.5rem);
    overflow: hidden auto;
    transition: left 0.3s;
    background-color: #fff;
  }

  &--open .panel__content {
    left: 0;
  }

  &--open .panel__overlay {
    left: 0;
    opacity: 0.5;
  }
}
