@use '../../../styles/mixins' as mixins;

.image-gallery {
  &__swiper {
    position: relative;
    height: 22.5rem;

    &-clickable-slide {
      cursor: pointer;
    }
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    -webkit-filter: blur(0.25rem) brightness(75%);
    filter: blur(0.25rem) brightness(75%);
  }

  &__preview {
    max-height: 22.5rem;
    max-width: 100%;
    margin: auto;
    user-select: none;
    z-index: 1;
  }

  &__caption {
    @include mixins.font-style(0.875rem, #98a1a6);

    margin-top: 0.5rem;
    line-height: 1.5rem;
    word-break: break-word;
    white-space: pre-wrap;
  }

  &__modal-swiper-slide {
    margin: auto;
    height: 100vh;
  }

  &__modal-preview {
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
  }
}
