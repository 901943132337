@use '../../../../styles/mixins' as mixins;

.change-author {
  position: relative;

  &__popup {
    @include mixins.flex-column(flex-start);

    gap: 1rem;
    position: absolute;
    top: 1.5rem;
    left: 0;
    box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.2);
    background-color: #fff;
    width: 20rem;
    padding: 1rem;
    border: 0.0625rem solid #ebebeb;
    z-index: 3;
  }

  &__label {
    @include mixins.ellipsis(14rem);
  }

  &__name {
    @include mixins.font-style(1rem, #1e1e1e);
    @include mixins.ellipsis(calc(100% - 4rem));

    line-height: 1.25rem;

    &--default {
      @include mixins.ellipsis(calc(100% - 9rem));
    }
  }

  &__name-default {
    @include mixins.font-style(1rem, #1e1e1e, 600);

    line-height: 1.25rem;
  }
}
