@use '../../../../styles/mixins' as mixins;

.category {
  @include mixins.flex-column(initial);

  gap: 1rem;

  &__scrollable {
    @include mixins.flex-column(initial, flex-start);
    @include mixins.custom-scrollbar();

    gap: 1.5rem;
    max-height: calc(100vh - 11rem);
  }

  &__title {
    @include mixins.font-style(1.25rem, #43445b, 600);
  }

  &__row {
    @include mixins.flex-row(center, flex-start);

    gap: 1rem;

    &-switch {
      width: 50%;
    }
  }

  &__templates {
    @include mixins.flex-column(initial);

    gap: 0.5rem;

    &-item {
      @include mixins.flex-row(center, flex-start);

      padding: 0.5rem;
      gap: 0.5rem;
      border: 0.0625rem solid #d7dbdd;
      background-color: #f8f8f8;

      &-image {
        width: 6.25rem;
        height: 5rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        flex-shrink: 0;
      }

      &-info {
        @include mixins.flex-column(initial);

        align-self: flex-start;

        &-name {
          @include mixins.font-style(0.875rem, #293033, 600);

          line-height: 1.25rem;
          word-break: break-word;
          white-space: pre-wrap;
        }

        &-text {
          @include mixins.font-style(0.875rem, #6d767c);

          line-height: 1.25rem;
          word-break: break-word;
          white-space: pre-wrap;
        }
      }

      &-edit {
        margin-left: auto;
      }
    }
  }

  &__button {
    margin-left: auto;
  }
}
