@use '../../../../styles/mixins' as mixins;

$prefix: '--create-card-thread';

.thread-post {
  @include mixins.flex-column(flex-start);

  &__scrollable {
    @include mixins.flex-column(flex-start, flex-start);
    @include mixins.custom-scrollbar();

    gap: 1rem;
    padding: 1rem;
    width: calc(100% - 2rem);
    max-height: calc(100vh - 18rem);
    overflow-x: hidden;

    &--mobile {
      max-height: calc(100vh - 25rem);
    }

    &--has-tags {
      max-height: calc(100vh - 20rem);
      padding-bottom: 0;
    }
  }

  &__description {
    @include mixins.flex-row(flex-start, flex-start);

    gap: 0.75rem;
    padding: 1.25rem;
    width: calc(100% - 2.5rem);
    background: var(#{$prefix}-background-color);
    border-radius: 0.5rem;

    &-text {
      @include mixins.flex-column(flex-start);
      @include mixins.font-style(0.875rem, var(#{$prefix}-text-color));

      gap: 0.5rem;

      &--title {
        @include mixins.font-style(1rem, var(#{$prefix}-title-color), 600);
      }
    }
  }

  &__input {
    @include mixins.font-style(1.5rem, var(#{$prefix}-input-color), 600);

    line-height: 2rem;
    border: 0;
    padding: 0;
  }

  &__tags {
    padding: 1rem;
    margin-left: auto;
  }
}
