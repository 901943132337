@mixin container {
  margin: 0 auto;
  max-width: var(--container-max-width);
}

@mixin font-style($font-size, $color, $font-weight: 400) {
  color: $color;
  font-size: $font-size;
  font-weight: $font-weight;
}

@mixin flex-row($align-items: center, $justify-content: center) {
  display: flex;
  flex-direction: row;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin flex-column($align-items: center, $justify-content: center) {
  display: flex;
  flex-direction: column;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin ellipsis($max-width: 100%, $white-space: nowrap) {
  max-width: $max-width;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: $white-space;
}

@mixin animation($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin animation-use($name, $time) {
  -webkit-animation: $name $time infinite linear;
  -moz-animation: $name $time infinite linear;
  -o-animation: $name $time infinite linear;
  animation: $name $time infinite linear;
}

@mixin animation-delay($time) {
  -webkit-animation-delay: $time;
  -moz-animation-delay: $time;
  -o-animation-delay: $time;
  animation-delay: $time;
}

@mixin position-centered($position: absolute) {
  position: $position;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@mixin transform($function) {
  -webkit-transform: $function;
  -moz-transform: $function;
  -ms-transform: $function;
  -o-transform: $function;
  transform: $function;
}

@mixin custom-scrollbar($type: vertical) {
  @if $type == vertical {
    overflow-y: auto;
  } @else {
    overflow-x: auto;
  }

  &::-webkit-scrollbar {
    background-color: transparent;

    @if $type == vertical {
      width: 0.35rem;
    } @else {
      height: 0.35rem;
    }
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &::-webkit-scrollbar-thumb {
    border: 0.25rem solid transparent;
    border-radius: 0.5rem;
    box-shadow: inset 0 0 0 0.625rem;
  }
}
