@use '../../../../styles/mixins' as mixins;

$prefix: '--profile-feed';

.profile-feed {
  @include mixins.flex-column(initial);

  gap: 1.5rem;

  &__header {
    @include mixins.flex-row(center, space-between);

    &-title {
      @include mixins.font-style(2rem, var(#{$prefix}-title-color), 600);

      line-height: 2.75rem;
    }
  }

  &__content {
    @include mixins.flex-column(initial);

    gap: 1.5rem;
    max-width: var(--content-max-width);
    padding-bottom: var(--card-bottom-spacing);

    &-cards {
      @include mixins.flex-column(initial);

      gap: 1.5rem;
    }
  }
}
