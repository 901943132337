@use '../../styles/mixins' as mixins;

.notifications {
  @include mixins.flex-column(initial);

  gap: 1.5rem;

  &__header {
    @include mixins.flex-row();

    width: 100%;

    &-title {
      @include mixins.font-style(2rem, #43445b, 600);

      line-height: 2.5rem;
    }

    &-button {
      margin-left: auto;
    }
  }

  &__content {
    @include mixins.flex-column(initial);

    gap: 1.5rem;
    margin-top: 1rem;

    &-intro {
      @include mixins.font-style(1rem, #888b90);

      line-height: 1.5rem;
    }

    &-filters {
      @include mixins.flex-row();

      gap: 1.5rem;

      &-search {
        position: relative;
        width: 100%;

        &-clear {
          position: absolute;
          right: 0.75rem;
          top: 0.75rem;
        }
      }
    }

    &-list {
      @include mixins.flex-column(initial);

      gap: 1rem;
    }
  }

  &__loader {
    @include mixins.flex-column(initial);

    gap: 1.5rem;
  }
}
