@use '../../../../styles/mixins' as mixins;

$prefix: '--notifications';

.content {
  @include mixins.flex-column(flex-start);

  &__title {
    @include mixins.font-style(1rem, var(#{$prefix}-primary-text-color), 600);

    line-height: 1.5rem;
    word-break: break-word;
    white-space: pre-wrap;
  }

  &__message {
    @include mixins.font-style(0.875rem, var(#{$prefix}-primary-text-color));

    line-height: 1.25rem;
    word-break: break-word;
    white-space: pre-wrap;
  }

  &__link-icon {
    display: inline-flex;
    position: relative;
    top: 0.25rem;
  }
}
