@use '../../../styles/mixins' as mixins;

$prefix: '--story-tab';

.story-list {
  @include mixins.flex-column(initial, flex-start);

  gap: 1rem;

  &__item {
    @include mixins.flex-row();

    gap: 1rem;
    padding: 0.75rem;
    border: 0.0625rem solid var(#{$prefix}-border-color);
    box-sizing: border-box;
    transition: box-shadow 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
    background-color: var(#{$prefix}-background-color);
    cursor: pointer;

    &:hover {
      box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.2);
    }

    &-image-wrapper {
      @include mixins.flex-row();

      width: 7.5rem;
      max-height: 5rem;
      flex-shrink: 0;
      overflow: hidden;
    }

    &-image {
      max-width: 100%;
      object-fit: cover;
      transform-origin: 50% 50%;
      transition: transform 0.25s ease-in-out;
    }

    &-description {
      width: 100%;
    }

    &-title,
    &-subTitle {
      word-break: break-word;
      white-space: pre-wrap;
    }

    &-title {
      @include mixins.font-style(1.25rem, var(#{$prefix}-title-color), 600);

      line-height: 1.75rem;
    }

    &-subTitle {
      @include mixins.font-style(0.875rem, var(#{$prefix}-subtitle-color));

      line-height: 1.25rem;
    }

    &:hover {
      .story-list__item-title {
        color: var(#{$prefix}-title-hover-color);
      }

      .story-list__item-subTitle {
        color: var(#{$prefix}-subtitle-hover-color);
      }
    }
  }
}
