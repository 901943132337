@use '../../styles/mixins' as mixins;

$prefix: '--story-feed'; //TODO add --news-feed prefix

.container {
  @include mixins.flex-column(initial);

  gap: 1.5rem;

  &__header {
    @include mixins.flex-row(center, space-between);

    &-title {
      @include mixins.font-style(2rem, var(#{$prefix}-headline-color), 600);

      line-height: 2.75rem;
    }

    &-switcher {
      margin-left: auto;
    }
  }
}
