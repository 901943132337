@use '../../../styles/mixins' as mixins;

.search-feed-admin-right-side {
  @include mixins.flex-column(initial, flex-start);
  @include mixins.custom-scrollbar();

  position: sticky;
  width: 18.75rem;
  top: 6rem;
  height: calc(100vh - 7rem);
  gap: 1rem;
}
