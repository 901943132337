@use '../../../styles/mixins' as mixins;

.join-chat {
  @include mixins.flex-column();

  background-color: #f3f3f6;
  width: calc(100% - 6rem);
  padding: 2rem;
  gap: 1rem;
  margin: 1rem;

  &__name {
    @include mixins.font-style(1.25rem, #43445b, 600);

    word-break: break-word;
  }

  &__purpose {
    @include mixins.font-style(1rem, #787878);

    word-break: break-word;
  }
}
