@use '../../../../styles/mixins' as mixins;

$prefix: '--card';

.card-wrapper {
  padding-bottom: var(#{$prefix}-spacing);
  border: 0.0625rem solid var(#{$prefix}-border-color);
  box-sizing: border-box;
  user-select: none;
  transition: box-shadow 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
  background-color: var(#{$prefix}-background-color);

  &:hover {
    box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.2);
  }

  &__headline {
    @include mixins.font-style(1.25rem, var(#{$prefix}-comment-color), 600);

    line-height: 1.75rem;
    white-space: pre-wrap;
    word-break: break-word;
  }

  &__child {
    width: 100%;

    &--clickable {
      cursor: pointer;
    }
  }

  &__content > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__author-time {
    @include mixins.flex-row(center, flex-start);

    gap: 0.25rem;
    width: 100%;
  }

  &__content-author {
    @include mixins.font-style(0.875rem, var(#{$prefix}-by-author-color));
    @include mixins.ellipsis();
  }

  &__posted-time {
    @include mixins.font-style(0.875rem, var(#{$prefix}-posted-time-color));

    line-height: 1.25rem;
    flex-shrink: 0;
  }

  &__reactions-counter {
    scroll-margin-top: calc(var(--navbar-height));
  }

  &__action-pane {
    @include mixins.flex-row(center, flex-start);

    width: 100%;
    scroll-margin-top: var(--navbar-height);
    gap: 1rem;

    &--mobile {
      gap: 1.5rem;
    }
  }
}
