@use '../../../styles/mixins' as mixins;

$primary-prefix: '--btn-primary';
$secondary-prefix: '--btn-secondary';

@include mixins.animation(loading) {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.button {
  font-size: 1rem;
  font-weight: 600;
  padding: 0.4rem 1.5rem;
  border-radius: 0.25rem;
  border: 0.125rem solid;
  line-height: 1.5rem;
  transition: 0.3s;
  cursor: pointer;

  &--has-options {
    border-radius: 0.25rem 0 0 0.25rem;
  }

  &--full-width {
    width: 100%;
  }

  &--loading {
    position: relative;
    padding-right: 3.5rem;

    &::after {
      @include mixins.animation-use(loading, 2s);

      content: '';
      position: absolute;
      width: 1rem;
      height: 1rem;
      top: 0;
      right: 1.5rem;
      bottom: 0;
      margin: auto;
      border: 0.25rem solid transparent;
      border-color: #fff;
      border-left-color: transparent;
      border-radius: 50%;
    }
  }

  &-wrapper {
    @include mixins.flex-row(center, flex-start);

    gap: 0.1rem;

    &--full-width {
      width: 100%;
    }

    &--non-clickable {
      pointer-events: none;
    }
  }
}

.options {
  border: 0.125rem solid;
  border-radius: 0 0.25rem 0.25rem 0;

  &--only {
    border-radius: 0.25rem;
  }

  &--full-width {
    width: 100%;
  }

  &__icon-label {
    padding: 0.4rem;
    justify-content: space-between;
  }

  &__label {
    line-height: 1.5rem;
  }
}

.button--primary,
.options--primary {
  background-color: var(#{$primary-prefix}-background-color);
  color: var(#{$primary-prefix}-text-color);
  border-color: var(#{$primary-prefix}-border-color);

  &:hover {
    background-color: var(#{$primary-prefix}-background-hover-color);
    color: var(#{$primary-prefix}-text-hover-color);
    border-color: var(#{$primary-prefix}-border-hover-color);
  }
}

.button--primary:disabled,
.options--primary-disabled {
  background-color: var(#{$primary-prefix}-background-disabled-color);
  color: var(#{$primary-prefix}-text-disabled-color);
  border-color: var(#{$primary-prefix}-border-disabled-color);
  pointer-events: none;
}

.button--secondary,
.options--secondary {
  background-color: var(#{$secondary-prefix}-background-color);
  color: var(#{$secondary-prefix}-text-color);
  border-color: var(#{$secondary-prefix}-border-color);

  &:hover {
    background-color: var(#{$secondary-prefix}-background-hover-color);
    color: var(#{$secondary-prefix}-text-hover-color);
    border-color: var(#{$secondary-prefix}-border-hover-color);
  }
}

.button--secondary:disabled,
.options--secondary-disabled {
  background-color: var(#{$secondary-prefix}-background-disabled-color);
  color: var(#{$secondary-prefix}-text-disabled-color);
  border-color: var(#{$secondary-prefix}-border-disabled-color);
  pointer-events: none;
}

.button--danger,
.options--danger {
  background-color: var(--color-danger);
  border-color: var(--color-danger);
  color: #fff;

  &:hover {
    background-color: var(--color-danger-hover);
    border-color: var(--color-danger-hover);
    color: #fff;
  }
}
