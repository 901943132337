@use '../../styles/mixins' as mixins;

$prefix: '--posted-in';
$link-prefix: '--base-link';

.posted-in {
  @include mixins.flex-row(center, flex-start);

  white-space: nowrap;
  margin: 1rem 0;

  &__label {
    @include mixins.font-style(1rem, var(#{$prefix}-color), 600);

    line-height: 1.25rem;
  }

  &__value {
    @include mixins.font-style(1rem, #787878);

    line-height: 1.25rem;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &--clickable {
      @include mixins.font-style(1rem, var(#{$link-prefix}-text-color), 600);

      cursor: pointer;

      &:hover {
        color: var(#{$link-prefix}-text-hover-color);
      }
    }
  }
}
