@use '../../../../styles/mixins' as mixins;

$card-prefix: '--card';
$content-prefix: '--card-content';

.full-post {
  @include mixins.flex-column(flex-start, flex-start);

  &__scrollable {
    @include mixins.flex-column(flex-start, flex-start);
    @include mixins.custom-scrollbar();

    gap: 1rem;
    padding: 1rem 1rem 0 1rem;
    width: calc(100% - 2rem);
    max-height: calc(100vh - 20.5rem);
    overflow-x: hidden;

    &--mobile {
      max-height: calc(100vh - 25rem);
    }
  }

  &__fields {
    @include mixins.flex-column(flex-start, flex-start);

    gap: 0.5rem;
    width: 100%;
  }

  &__sourceName,
  &__title,
  &__headline,
  &__abstract,
  &__author {
    border: 0;
    padding: 0;
  }

  &__headline {
    @include mixins.font-style(1rem, var(#{$card-prefix}-comment-color));

    line-height: 1.5rem;
  }

  &__sourceName {
    @include mixins.font-style(0.75rem, var(#{$content-prefix}-source-color), 500);

    line-height: 1.25rem;
  }

  &__title {
    @include mixins.font-style(1.5rem, var(#{$content-prefix}-headline-color), 600);

    line-height: 2rem;
  }

  &__abstract {
    @include mixins.font-style(1rem, var(#{$content-prefix}-text-color));

    line-height: 1.5rem;
  }

  &__author {
    @include mixins.font-style(1rem, var(#{$card-prefix}-by-author-color));

    line-height: 1.5rem;
  }

  &__editor {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f3f3f6;

    &-header {
      @include mixins.flex-row(center, flex-start);

      padding: 0 5rem;
      height: 4rem;
      background: #43445b;

      &--mobile {
        padding: 0 1rem;
      }

      &-preview {
        margin-left: auto;
      }

      &-close {
        position: relative;
        width: 1rem;
        height: 1rem;
        margin-left: 2rem;
        cursor: pointer;

        &:before,
        &:after {
          @include mixins.transform(rotate(45deg));

          position: absolute;
          content: '';
          left: 0.35rem;
          height: 100%;
          width: 0.125rem;
          background-color: #b3b3b3;
        }

        &:after {
          @include mixins.transform(rotate(-45deg));
        }
      }
    }

    &-content {
      @include mixins.custom-scrollbar();

      margin: 0 auto;
      padding: 2rem 5rem;
      width: 52rem;
      background: #fff;
      height: calc(100% - 12rem);
      overflow-y: auto;
      overflow-x: hidden;

      &--preview {
        padding: 3rem;
        width: 40rem;
        height: calc(100% - 10rem);
      }

      &--mobile,
      &--preview-mobile {
        width: calc(100% - 2rem);
        padding: 1rem;
        height: calc(100% - 14rem);
      }

      &--preview-mobile {
        height: calc(100% - 10rem);
      }

      &--hidden {
        display: none;
      }
    }

    &-footer {
      @include mixins.flex-row(center, flex-start);

      gap: 1rem;
      padding: 0 calc((100% - 52rem - 10rem) / 2);
      height: 4rem;
      border: 0.0625rem solid;
      border-color: transparent;
      border-top-color: #ebebeb;
      box-sizing: border-box;
      background: #fff;

      &--mobile {
        padding: 0 1rem;
      }

      &-cancel {
        margin-left: auto;
      }
    }
  }

  &__pane {
    @include mixins.flex-row(flex-start, flex-start);

    padding: 1rem;
    width: calc(100% - 2rem);

    &-fields {
      @include mixins.flex-row(flex-start, flex-start);

      gap: 1rem;
      margin-left: auto;
    }
  }

  &__fields-wrapper {
    @include mixins.flex-column(flex-start, flex-start);

    gap: 1rem;
    position: relative;
    width: 100%;
    padding: 1rem;
    border: 0.0625rem solid #ebebeb;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
    box-shadow: 0px 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.2);
  }

  &__edit {
    position: absolute;
    right: 1rem;
    top: 1rem;
    border-radius: 50%;
    border: 0.0625rem solid #ebebeb;
    box-shadow: 0 0 0.2rem #293033;
    opacity: 0.5;
    transition: opacity 0.3s;
    cursor: pointer;
    z-index: 1;

    &:hover {
      opacity: 1;
    }
  }
}
