@use '../../../../../styles/mixins' as mixins;

$prefix: '--details-block-table';

:global(div.tc-table) {
  @include mixins.font-style(1rem, #1e1e1e);
}

:global(.tc-table--heading .tc-row:first-child) {
  color: var(#{$prefix}-header-text-color);
  background-color: var(#{$prefix}-header-background-color);
}
