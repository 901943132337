@use '../../../../../styles/mixins' as mixins;

.create-card-settings {
  width: 100%;

  &__tags {
    @include mixins.flex-column(initial, flex-start);

    gap: 1rem;
    margin: 1rem 0 15rem 0;
  }
}
