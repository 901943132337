@use '../../../styles/mixins' as mixins;

$prefix: '--special-teaser';
$card-teaser-prefix: '--card-teaser';

.teaser {
  background: var(#{$prefix}-background-color);
  cursor: pointer;

  &__source {
    @include mixins.font-style(1rem, var(#{$prefix}-source-color));

    padding: 1rem 1.25rem;
    line-height: 1.5rem;
    white-space: pre-wrap;
    word-break: break-word;
  }

  &__preview {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  &__headline {
    padding: 1rem 1.25rem 0 1.25rem;
    line-height: 1.25rem;
    white-space: pre-wrap;
    word-break: break-word;
  }

  &__posted-time {
    @include mixins.font-style(0.75rem, var(#{$prefix}-time-color));

    line-height: 1rem;
    padding: 0 1.25rem 1rem 1.25rem;
    margin-top: 0.375rem;
  }

  &__big {
    position: relative;
    width: 100%;
    height: 24rem;

    &-preview {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }

    &-content {
      @include mixins.flex-column(flex-start, flex-start);

      gap: 0.5rem;
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 1rem;
      max-width: calc(100% - 2rem);
      background: var(#{$card-teaser-prefix}-background-color);

      &-source {
        @include mixins.font-style(0.875rem, var(#{$card-teaser-prefix}-text-color));
        @include mixins.ellipsis();

        line-height: 1.25rem;
      }

      &-headline {
        @include mixins.font-style(1rem, var(#{$card-teaser-prefix}-text-color), 600);

        line-height: 1.5rem;

        &-icon {
          display: inline-flex;
          position: relative;
          top: 0.25rem;
          left: 0.25rem;
        }
      }
    }
  }
}
