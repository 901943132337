.popup {
  position: relative;
  cursor: pointer;

  &__content {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.045755),
      0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.101508);
    z-index: 999;
  }
}
