@use '../../../styles/mixins' as mixins;

$prefix: '--radio';

.radio {
  @include mixins.flex-row(center, flex-start);

  position: relative;
  padding-left: 1.5rem;
  cursor: pointer;

  &--small {
    @include mixins.font-style(0.75rem, #1e1e1e);

    line-height: 1rem;
  }

  &--medium {
    @include mixins.font-style(0.875rem, #1e1e1e);

    line-height: 1.15rem;
  }

  &--large {
    @include mixins.font-style(1rem, #1e1e1e);

    line-height: 1.25rem;
  }

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border: 0.0625rem solid #b3b3b3;
    box-sizing: border-box;
  }

  &--checked {
    &::after {
      @include mixins.position-centered();

      content: '';
      left: 0.5rem;
      top: 0.5rem;
      width: 0.5rem;
      height: 0.5rem;
      background-color: var(#{$prefix}-background-color);
      border-radius: 50%;
    }
  }
}
