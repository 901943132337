@use '../../../../../../styles/mixins' as mixins;

.story {
  @include mixins.flex-row(center, space-between);

  width: 100%;
  gap: 1rem;

  &__fields {
    @include mixins.flex-column(initial);

    max-width: calc(100% - 2.5rem - 1rem);
    gap: 0.25rem;

    &-title {
      @include mixins.font-style(0.875rem, #1e1e1e, 600);
      @include mixins.ellipsis();

      line-height: 1rem;
    }

    &-subTitle {
      @include mixins.font-style(0.875rem, #888b90);
      @include mixins.ellipsis();

      line-height: 1rem;
    }
  }

  &__teaser {
    width: 2.5rem;
    height: 2.5rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 0.5rem;
  }
}
