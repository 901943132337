@use '../../../../styles/mixins' as mixins;

$prefix: '--card-thread';

.thread {
  @include mixins.flex-column(flex-start, flex-start);

  position: relative;
  gap: 0.5rem;
  width: calc(100% - 3rem);
  padding: 1.5rem;
  background: var(#{$prefix}-background-color);
  border-radius: 1rem 1rem 0 1rem;

  &:after {
    content: '';
    position: absolute;
    top: calc(100% - 1rem);
    right: 0;
    width: 0;
    height: 0;
    border: 1rem solid black;
    border-color: transparent var(#{$prefix}-background-color) transparent transparent;
  }

  &__discussion {
    @include mixins.flex-row();
    @include mixins.font-style(0.875rem, var(#{$prefix}-discussion-color));

    gap: 0.5rem;
    line-height: 1.25rem;
  }

  &__title {
    @include mixins.font-style(1.5rem, var(#{$prefix}-title-color), 600);

    width: 100%;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    line-height: 2rem;
  }
}
