@use '../../../../styles/mixins' as mixins;

$prefix: '--card-social';

.wrapper {
  border: 0.0625rem solid var(#{$prefix}-border-color);
  box-sizing: border-box;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.header-wrapper {
  cursor: pointer;

  &--inner {
    margin: 0.75rem 0.75rem 0 0.75rem;
  }
}

.quote,
.headline {
  @include mixins.font-style(1rem, var(#{$prefix}-text-color));

  line-height: 1.5rem;
  white-space: pre-wrap;
  word-break: break-word;
  margin-bottom: 0.75rem;
}

.headline {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.icon {
  margin-left: auto;
}

.author-info {
  @include mixins.flex-row(center, flex-start);
  width: 100%;

  gap: 0.5rem;
}
