@use '../../../styles/mixins' as mixins;

.chats-list {
  @include mixins.flex-column(flex-start, initial);

  width: 100%;

  &__content {
    width: 100%;

    &-loader {
      @include mixins.flex-column(flex-start, initial);

      width: calc(100% - 2rem);
      margin: 1rem auto 0;
    }

    &-infinite-scroll {
      @include mixins.custom-scrollbar();

      overflow-y: auto;
    }

    &-tab-names {
      padding: 0 1rem;
    }

    &-no-data {
      @include mixins.flex-column(center, initial);

      gap: 0.5rem;
      padding: 2rem 1rem 0 1rem;

      &-label {
        @include mixins.font-style(1rem, #787878);

        text-align: center;
      }
    }
  }
}
