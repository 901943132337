@use '../../../../styles/mixins' as mixins;

.code {
  @include mixins.font-style(1rem, #1e1e1e);

  width: calc(100% - 2rem);
  padding: 1rem;
  line-height: 1.5rem;
  background-color: #f3f3f6;
  border: 0.0625rem solid #ebebeb;
  word-break: break-word;
  white-space: pre-wrap;
}
