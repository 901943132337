@use '../../styles/mixins' as mixins;

.placeholder {
  @include mixins.flex-column(center, initial);

  gap: 0.75rem;

  &__img {
    max-width: 100%;
  }

  &__title {
    @include mixins.font-style(1.75rem, #43445b, 600);

    line-height: 2rem;
    text-align: center;
  }

  &__subTitle {
    @include mixins.font-style(1rem, #888b90);

    line-height: 1.25rem;
    text-align: center;
  }
}

.chats {
  @include mixins.flex-row(flex-start, initial);

  position: relative;
  border: 0.0625rem solid #ebebeb;
  border-radius: 0.5rem;
  height: calc(100vh - 7rem);
  box-sizing: border-box;
  background-color: #fff;

  &__header {
    @include mixins.flex-column(flex-start, initial);

    width: 23.125rem;
    height: calc(100vh - 7rem - 0.0625rem);
    border-right: 0.0625rem solid #ebebeb;
    flex-shrink: 0;

    &-search {
      @include mixins.flex-row(center, space-between);

      width: calc(100% - 2rem);
      height: 2.5rem;
      padding: 1rem;
      border-bottom: 0.0625rem solid #ebebeb;

      &-title {
        @include mixins.font-style(1.5rem, #43445b, 600);

        line-height: 1.75rem;
      }

      &-icons {
        @include mixins.flex-row();

        gap: 1rem;
      }
    }
  }
}
