@use '../../../../styles/mixins' as mixins;

.stories-user-feed {
  @include mixins.flex-column(initial);

  max-width: var(--content-max-width);
  padding-bottom: var(--card-bottom-spacing);

  &__cards {
    @include mixins.flex-column(initial);

    gap: 1.5rem;
  }
}
