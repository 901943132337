@use '../../../../styles/mixins' as mixins;

$link-prefix: '--base-link';
$message-prefix: '--chats-message';
$message-other-prefix: '--chats-message-other';

.author {
  @include mixins.flex-row(flex-start, flex-start);

  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: -1.5rem;
  max-width: calc(100% - 3rem);
  cursor: pointer;

  &__name {
    @include mixins.ellipsis();
    @include mixins.font-style(0.875rem, #1e1e1e, 600);
  }
}

.message {
  @include mixins.flex-row(center, flex-start);

  position: relative;
  gap: 0.75rem;
  margin-left: auto;
  max-width: calc(100% - 6rem);

  &--has-reaction {
    margin-bottom: 1.5rem;
  }

  &--deleted::after {
    border-color: transparent #c9c9c9 transparent transparent;
  }

  &__content {
    @include mixins.font-style(1rem, var(#{$message-prefix}-text-color));

    position: relative;
    line-height: 1.5rem;
    padding: 0.625rem 0.625rem 0.825rem 0.625rem;
    background-color: var(#{$message-prefix}-background-color);
    word-break: break-word;
    white-space: pre-wrap;
    border-radius: 0.5rem;

    &-text {
      min-width: 5rem;
    }

    &-emoji {
      font-size: 2rem;
      line-height: 2.5rem;
    }

    &-card {
      width: 23rem;
    }

    &-time {
      @include mixins.font-style(0.7rem, var(#{$message-prefix}-date-color));

      position: absolute;
      right: 0.25rem;
      bottom: -0.25rem;
    }
  }

  &__reaction {
    position: absolute;
    right: 0;
    bottom: -2rem;
  }

  &__icon {
    opacity: 0;
  }

  &:hover .message__icon {
    opacity: 1;
  }

  &--other {
    margin-left: 3rem;
    margin-right: auto;
    flex-direction: row-reverse;
    justify-content: flex-end;
    max-width: calc(100% - 10rem);

    & .message__content {
      color: var(#{$message-other-prefix}-text-color);
      background-color: var(#{$message-other-prefix}-background-color);
    }

    & .message__content-time {
      color: var(#{$message-other-prefix}-date-color);
    }

    & .message__reaction {
      left: 0;
    }
  }

  &--deleted .message__content {
    background-color: #c9c9c9;
  }

  &__emoji-picker {
    @include mixins.position-centered();

    margin-top: -2rem;
    z-index: 2;
  }

  a {
    text-decoration: none;
    color: var(#{$link-prefix}-text-color);

    &:hover {
      color: var(#{$link-prefix}-text-hover-color);
    }
  }
}
