@use '../../../styles/mixins' as mixins;

.copyright {
  @include mixins.font-style(0.75rem, white);
  @include mixins.ellipsis(calc(100% - 0.5rem));

  position: absolute;
  bottom: 0.25rem;
  left: 0.25rem;
  line-height: 1.125rem;
  background: rgba(64, 72, 77, 0.8);
  padding: 0.375rem 0.5rem;
  box-sizing: border-box;
  z-index: 1;
}
