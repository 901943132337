@use '../../../styles/mixins' as mixins;

.user-feed {
  @include mixins.flex-column(initial);

  position: relative;
  margin-top: 1.5rem;

  &__filter {
    position: absolute;
    right: 0;
    top: 1rem;
  }

  &__tab-content {
    margin-top: 1.5rem;
    min-height: 51.25rem;
  }
}
