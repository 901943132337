@use '../../styles/mixins' as mixins;

@include mixins.animation(tooltip-visibility) {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.analytics {
  @include mixins.flex-column(initial, flex-start);

  gap: 0.5rem;
  margin-bottom: 10rem;
  position: relative;

  &__header {
    @include mixins.font-style(1.5rem, #43445b, 600);

    line-height: 2rem;
  }

  &__export {
    position: absolute;
    right: 0;
    top: 2.5rem;
  }

  &__tab-content {
    @include mixins.flex-column(initial, flex-start);

    gap: 1rem;
    margin-top: 1rem;

    &-filters {
      @include mixins.flex-row(center, flex-start);

      width: 100%;
      gap: 0.25rem;

      & > :first-child {
        flex-shrink: 0;
      }

      &-select {
        max-width: 9rem;
      }

      &-exclude-tags {
        @include mixins.flex-column(initial, flex-start);

        gap: 0.5rem;
      }
    }
  }

  &__chart {
    border: 0.0625rem solid #ebebeb;
    background-color: #fff;

    &-header {
      @include mixins.flex-row(center, flex-start);

      padding: 1rem;
      border-bottom: 0.0625rem solid #ebebeb;
      gap: 0.5rem;

      &-label {
        @include mixins.font-style(1rem, #1e1e1e, 600);

        line-height: 1.5rem;
      }

      &-select {
        margin-left: auto;
        max-width: 15rem;
      }
    }

    &-no-records {
      padding: 2rem;
      text-align: center;
    }

    &-stats {
      @include mixins.flex-row(center, flex-start);

      width: 100%;
      flex-wrap: wrap;

      &-item {
        @include mixins.flex-row(center, flex-start);

        width: calc(100% / 3);
        border: 0.0625rem solid;
        border-color: transparent transparent #ebebeb transparent;
        box-sizing: border-box;
        padding: 1rem;
        gap: 0.5rem;

        &--column {
          @include mixins.flex-column();

          .analytics__chart-stats-item-right-side {
            margin-left: unset;
          }
        }

        &--hint:hover {
          position: relative;

          &::after {
            content: attr(data-hint);
            position: absolute;
            width: max-content;
            max-width: 19rem;
            left: 0;
            bottom: calc(100% + 0.75rem);
            padding: 0.75rem;
            background: #fff;
            border: 0.0625rem solid #ebebeb;
            border-radius: 0.25rem;
            word-break: break-word;
            white-space: pre-wrap;
            box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.2);
            z-index: 3;
          }
        }

        &:nth-child(3n - 1) {
          border-right-color: #ebebeb;

          &:not(.analytics__chart-stats-item--inline):not(.analytics__chart-stats-item--has-total) {
            border-left-color: #ebebeb;
          }
        }

        &--has-total:nth-child(3n) {
          border-right-color: #ebebeb;
        }

        &--inline {
          flex: 1;
          border-right-color: #ebebeb;

          &:last-child {
            border-right-color: transparent;
          }
        }

        &--pointer {
          cursor: pointer;
        }

        &--disabled * {
          opacity: 0.3;
        }

        &--full-width {
          width: 100%;
        }

        &-color {
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
        }

        &-label {
          @include mixins.flex-row();
          @include mixins.font-style(0.875rem, #1e1e1e, 600);

          gap: 0.5rem;
        }

        &-right-side {
          @include mixins.flex-row(center, flex-start);

          margin-left: auto;
          gap: 0.5rem;
        }

        &-value {
          @include mixins.font-style(1.25rem, #1e1e1e, 600);
        }
      }
    }

    &-tooltip {
      @include mixins.flex-column(initial, flex-start);
      @include mixins.animation-use(tooltip-visibility, 200ms); //Fix for tooltip flickering

      background: #fff;
      box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
      padding: 1rem;
      gap: 0.5rem;
      max-width: 30rem;

      &--reverse {
        flex-direction: column-reverse;
      }

      &-item {
        @include mixins.flex-row();

        gap: 0.5rem;

        &-color {
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
        }

        &-label {
          @include mixins.font-style(0.875rem, #1e1e1e);
        }

        &-value {
          @include mixins.font-style(0.875rem, #1e1e1e, 600);

          margin-left: auto;
        }
      }
    }

    &-height {
      height: 25rem;

      &-wrapper {
        @include mixins.flex-column(initial, flex-start);

        gap: 1rem;
        margin: 1rem 0;
      }

      &--content-interaction,
      &--content-interaction {
        height: 3.5rem;
      }
    }

    &-best {
      @include mixins.flex-column(initial, flex-start);

      padding: 1rem 1rem 0 1rem;
      gap: 0.5rem;

      &-label {
        @include mixins.font-style(0.875rem, #787878);
      }

      &-value {
        @include mixins.font-style(1.5rem, #1e1e1e, 600);
      }
    }
  }
}
