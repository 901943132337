@use '../../../styles/mixins' as mixins;

.category {
  @include mixins.flex-column(initial);

  gap: 1.5rem;
  padding-top: 1rem;

  &__intro {
    @include mixins.font-style(1rem, #6d767c);

    line-height: 1.5rem;
  }

  &__header {
    @include mixins.flex-row(center, space-between);

    &-title {
      @include mixins.font-style(1.25rem, #43445b, 600);
    }
  }

  &__no-content {
    background: #fff;
    padding: 2rem;
    text-align: center;
  }

  &__list {
    border-collapse: collapse;
    background-color: #fff;

    tr {
      border: 0.0625rem solid #dde1e4;
    }

    th {
      font-weight: 600;
      color: #43445b;
    }

    th,
    td {
      text-align: left;
      padding: 0.375rem 0.75rem;
      word-break: break-word;
      white-space: pre-wrap;
    }
  }

  &-removal {
    @include mixins.flex-column(initial);

    gap: 1rem;
  }
}
