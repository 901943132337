@use '../../../../../styles/mixins' as mixins;

$prefix: '--create-card';

.header {
  @include mixins.flex-row(center, flex-start);

  gap: 0.25rem;
  width: 100%;

  &__label {
    @include mixins.font-style(1rem, var(#{$prefix}-post-in-color), 600);

    flex-shrink: 0;
  }

  &__select {
    max-width: 12rem;
  }

  &__buttons {
    @include mixins.flex-row(center, flex-start);

    gap: 1rem;
    margin-left: auto;
  }
}
