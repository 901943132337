.swiper {
  width: 100%;
  height: 100%;
}

.swiper .swiper-slide {
  display: flex;
  flex-direction: column;
}

.swiper-button-next,
.swiper-button-prev {
  background-size: 100% auto;
  width: 1.75rem;
  height: 1.75rem;
  background: #fff url('../assets/icons/swiper-arrow-right.svg') no-repeat center;
  border-radius: 50%;
  box-shadow: 0 0 0.5rem #293033;

  &:after {
    display: none;
  }
}

.swiper-button-prev {
  background: #fff url('../assets/icons/swiper-arrow-left.svg') no-repeat center;
}

.swiper-pagination-bullet,
.swiper-pagination-bullet-active {
  width: 0.375rem;
  height: 0.375rem;
  background: #fff;
  opacity: 0.5;
}

.swiper-pagination-bullet-active {
  opacity: 1;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  pointer-events: all;
}
