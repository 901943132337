@use '../../../../styles/mixins' as mixins;

$prefix: '--notifications';

.notification {
  @include mixins.flex-row(flex-start, flex-start);

  gap: 0.75rem;
  padding: 0.75rem 0.75rem 0 0.75rem;
  transition: background-color 0.2s;
  cursor: default;

  &--has-link {
    cursor: pointer;

    &:hover {
      background-color: var(#{$prefix}-background-hover-color);
    }
  }

  &:last-child .notification__content {
    border-bottom: 0;
  }

  &__content {
    @include mixins.flex-column(flex-start);

    width: 100%;
    gap: 0.125rem;
    border-bottom: 0.0625rem solid var(#{$prefix}-border-color);
    padding-bottom: 0.75rem;

    &-delivered-time {
      @include mixins.font-style(0.875rem, var(#{$prefix}-secondary-text-color));

      line-height: 1.25rem;
    }
  }
}
