@use '../../../styles/mixins' as mixins;

$prefix: '--error-layout';

@include mixins.animation(blink) {
  0%,
  88%,
  94%,
  100% {
    height: 0.75rem;
    margin-top: 0;
  }
  91%,
  97% {
    height: 0;
    margin-top: 0.375rem;
  }
}

.error-layout {
  @include mixins.flex-column(center, flex-start);

  height: 100vh;
  background-color: var(#{$prefix}-outer-background-color);

  &__logo {
    margin: 1rem 0;
    max-width: 15rem;
    max-height: 7rem;
  }

  &__content {
    @include mixins.flex-column(center, flex-start);

    width: 24rem;
    gap: 2rem;
    padding: 1.5rem;
    background: var(#{$prefix}-background-color);
    border: 0.0625rem solid var(#{$prefix}-border-color);

    &--no-logo {
      margin-top: 7rem;
    }
  }

  &__header {
    text-align: center;

    &-title {
      @include mixins.font-style(1.4rem, var(#{$prefix}-title-color));

      line-height: 2rem;
    }

    &-subtitle {
      @include mixins.font-style(1.4rem, var(#{$prefix}-subtitle-color), 600);

      line-height: 2rem;
    }
  }

  &__body {
    @include mixins.flex-column(center, flex-end);

    position: relative;
    width: 17.5rem;
    height: 17.5rem;
    border-radius: 50%;
    background-color: var(#{$prefix}-page-outer-background-color);
    border: 3px solid var(#{$prefix}-page-outer-border-color);
    box-sizing: border-box;
    overflow: hidden;

    &-page {
      width: 11.75rem;
      height: 12.75rem;
      background: var(#{$prefix}-page-inner-background-color);
      border: 3px solid var(#{$prefix}-page-inner-border-color);
      border-radius: 0.25rem;
      box-sizing: border-box;

      &-404 {
        @include mixins.font-style(3rem, var(#{$prefix}-page-text-color), 600);

        text-align: center;
        margin-top: 2.125rem;
      }

      &-header {
        width: 100%;
        height: 1.375rem;
        background: var(#{$prefix}-page-header-color);
        border-bottom: 3px solid var(#{$prefix}-page-inner-border-color);
        box-sizing: border-box;
      }

      &-circle {
        display: inline-block;
        width: 0.75rem;
        height: 0.75rem;
        border: 2px solid var(#{$prefix}-page-circle-color);
        box-sizing: border-box;
        border-radius: 50%;
        margin-left: 0.5rem;
        margin-top: 0.25rem;
      }

      &-face {
        position: relative;
        margin: 2.125rem auto 0 auto;
        width: 3.5rem;
        height: 2.875rem;
      }

      &-eye {
        @include mixins.animation-use(blink, 3s);

        width: 0.75rem;
        height: 0.75rem;
        background: var(#{$prefix}-page-face-color);
        border-radius: 50%;
        float: left;

        &:last-of-type {
          float: right;
        }
      }

      &-smile {
        position: absolute;
        width: 1.5rem;
        height: 0.75rem;
        border-top-left-radius: 1.5rem;
        border-top-right-radius: 1.5rem;
        border: 0.25rem solid var(#{$prefix}-page-face-color);
        border-bottom: 0;
        left: 50%;
        bottom: 0;
        margin-left: -0.75rem;
        box-sizing: border-box;
      }

      &-cover {
        position: absolute;
        width: 100%;
        height: 5.125rem;
        left: 0.75rem;
        background-color: var(#{$prefix}-page-overlay-color);
        mask: url('../../../assets/icons/cloud.svg') no-repeat center;
        -webkit-mask: url('../../../assets/icons/cloud.svg') no-repeat center;

        &--not-found {
          left: 1.2rem;
          mask: url('../../../assets/icons/rock.svg') no-repeat center;
          -webkit-mask: url('../../../assets/icons/rock.svg') no-repeat center;
        }
      }
    }
  }
}
