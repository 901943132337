@use '../../../../styles/mixins' as mixins;

$prefix: '--login';

.divider {
  @include mixins.font-style(1rem, var(#{$prefix}-divider-text-color));

  position: relative;
  width: 100%;
  text-align: center;

  &:before,
  &:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0.5rem;
    height: 0.0625rem;
    width: calc(50% - 4.5rem);
    background: var(#{$prefix}-divider-border-color);
  }

  &:after {
    left: unset;
    right: 0;
  }
}

.forgot {
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  line-height: 1.5rem;
  text-align: left;
  cursor: pointer;
}

.sign-up {
  @include mixins.flex-row();

  width: 100%;
  gap: 0.5rem;

  &__label {
    @include mixins.font-style(1rem, var(#{$prefix}-sign-up-text-color));
  }

  &__link {
    display: inline;
    width: auto;
    font-size: 1rem;
    font-weight: 600;
  }
}
