@use '../../../styles/mixins' as mixins;

$link-prefix: '--base-link';

.dsa-report {
  @include mixins.flex-column(flex-start);

  gap: 0.5rem;

  &__intro {
    @include mixins.font-style(1rem, #43445b);

    line-height: 1.25rem;
    font-style: italic;
  }

  &__reasons {
    @include mixins.flex-column(flex-start);

    gap: 1rem;
    width: 100%;

    &-item {
      @include mixins.flex-row(center, space-between);

      width: 100%;
      gap: 1rem;
      cursor: pointer;
    }
  }

  &__links {
    @include mixins.font-style(1rem, #43445b);

    &-item {
      text-decoration: none;
      color: var(#{$link-prefix}-text-color);

      &:hover {
        color: var(#{$link-prefix}-text-hover-color);
      }
    }
  }

  &__anonymous {
    margin-bottom: 0.5rem;
  }

  &__consent {
    margin: 0.5rem 0;
  }

  &__buttons {
    @include mixins.flex-row(center, flex-end);

    width: 100%;
    gap: 0.5rem;
  }
}
