@use '../../../../styles/mixins' as mixins;

$prefix: '--details-block-header';

.header {
  word-break: break-word;
  white-space: pre-wrap;

  &.h1 {
    @include mixins.font-style(2.5rem, var(#{$prefix}-1-color), 600);

    line-height: 3rem;
  }

  &.h2 {
    @include mixins.font-style(2.25rem, var(#{$prefix}-2-color), 600);

    line-height: 2.75rem;
  }

  &.h3 {
    @include mixins.font-style(1.75rem, var(#{$prefix}-3-color), 600);

    line-height: 2.25rem;
  }

  &.h4 {
    @include mixins.font-style(1.5rem, var(#{$prefix}-4-color), 600);

    line-height: 2rem;
  }

  &.h5 {
    @include mixins.font-style(1.25rem, var(#{$prefix}-5-color));

    line-height: 1.75rem;
  }

  &.h6 {
    @include mixins.font-style(1rem, var(#{$prefix}-6-color), 600);

    line-height: 1.5rem;
  }
}
