@use '../../../styles/mixins' as mixins;

$link-prefix: '--base-link';

.user-mention {
  @include mixins.flex-row(center, flex-start);

  gap: 0.5rem;
  margin-top: 0.5rem;
  flex-wrap: wrap;

  &__link {
    @include mixins.font-style(1rem, var(#{$link-prefix}-text-color), 600);

    cursor: default;

    &--clickable {
      cursor: pointer;

      &:hover {
        color: var(#{$link-prefix}-text-hover-color);
      }
    }
  }
}
