@use '../../../../styles/mixins' as mixins;

$prefix: '--notifications';

.content {
  @include mixins.font-style(1rem, var(#{$prefix}-primary-text-color));

  white-space: pre-wrap;
  word-break: break-word;
}
