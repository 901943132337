@use '../../styles/mixins' as mixins;

$link-prefix: '--base-link';

.link {
  @include mixins.font-style(1rem, var(#{$link-prefix}-text-color));

  line-height: 1.25rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: var(#{$link-prefix}-text-hover-color);
  }
}
