@use '../../../../styles/mixins' as mixins;

.file-upload {
  @include mixins.flex-column();

  position: relative;
  gap: 1rem;
  padding: 1rem;
  width: calc(100% - 2rem);
  background-image: url("data:image/svg+xml,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' fill='none' stroke='silver' stroke-width='1' stroke-dasharray='7' stroke-dashoffset='0' stroke-linecap='square' /></svg>");

  &--mobile {
    padding: 0;
    width: 100%;
    background-image: none;
  }

  &--dropzone {
    background-image: url("data:image/svg+xml,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' fill='none' stroke='blue' stroke-width='1' stroke-dasharray='7' stroke-dashoffset='0' stroke-linecap='square' /></svg>");

    & * {
      pointer-events: none;
    }

    .file-upload__drop {
      opacity: 1;
    }
  }

  &__file {
    display: none;
  }

  &__drop {
    position: absolute;
    left: 0.125rem;
    top: 0.125rem;
    width: calc(100% - 0.25rem);
    height: calc(100% - 0.25rem);
    background-color: #fff;
    pointer-events: none;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

    &-label {
      @include mixins.position-centered();
      @include mixins.font-style(1rem, #b3b3b3);
    }
  }

  &__trigger {
    @include mixins.flex-row();

    gap: 0.5rem;
    width: 100%;

    &-input {
      padding: 0.5rem;
      width: calc(100% - 1rem);
    }

    &-button {
      flex-shrink: 0;
    }

    &-divider {
      @include mixins.font-style(0.875rem, #b3b3b3);
    }
  }
}
