@use '../../../../../styles/mixins' as mixins;

$card-prefix: '--card';
$content-prefix: '--card-content';
$social-prefix: '--card-social';
$link-prefix: '--base-link';

.link-post-quote {
  @include mixins.flex-column(flex-start, flex-start);

  width: 100%;

  &__scrollable {
    @include mixins.flex-column(flex-start, flex-start);
    @include mixins.custom-scrollbar();

    gap: 1rem;
    padding: 1rem;
    width: calc(100% - 2rem);
    max-height: calc(100vh - 18rem);
    overflow-x: hidden;

    &--mobile {
      max-height: calc(100vh - 25rem);
    }

    &--has-tags {
      max-height: calc(100vh - 20rem);
      padding-bottom: 0;
    }
  }

  &__url {
    @include mixins.flex-row(center, flex-start);

    gap: 0.25rem;
    width: calc(100% - 3rem);

    &-label {
      @include mixins.font-style(1rem, var(#{$content-prefix}-text-color));
    }

    &-value {
      @include mixins.ellipsis();

      flex-shrink: 0;
      text-decoration: none;
      color: var(#{$link-prefix}-text-color);
      cursor: pointer;

      &:hover {
        color: var(#{$link-prefix}-text-hover-color);
      }
    }
  }

  &__headline,
  &__content {
    border: 0;
    padding: 0;
  }

  &__headline {
    @include mixins.font-style(1rem, var(#{$card-prefix}-comment-color));

    line-height: 1.5rem;
  }

  &__content {
    @include mixins.font-style(1rem, var(#{$social-prefix}-text-color));

    line-height: 1.5rem;
  }

  &__author-info {
    pointer-events: none;
  }

  &__tags {
    padding: 1rem;
    margin-left: auto;
  }
}
