@use '../../../styles/mixins' as mixins;

.notification {
  @include mixins.flex-column(initial);

  gap: 0.5rem;
  padding: 1rem;
  border: 0.0625rem solid #ebebeb;
  border-radius: 0.25rem;
  background-color: #fff;

  &__top {
    @include mixins.flex-row(flex-start, space-between);

    gap: 0.5rem;

    &-text {
      @include mixins.flex-column(initial);

      gap: 0.5rem;
    }

    &-image-wrapper {
      @include mixins.flex-row();

      width: 7.5rem;
      max-height: 5rem;
      flex-shrink: 0;
      overflow: hidden;
    }
  }

  &__title {
    @include mixins.font-style(1rem, #43445b, 600);

    line-height: 1.25rem;
    word-break: break-word;
  }

  &__message {
    @include mixins.font-style(1rem, #888b90);

    line-height: 1.25rem;
    word-break: break-word;
  }

  &__url {
    @include mixins.ellipsis();
  }

  &__info {
    @include mixins.flex-row(flex-start);

    gap: 0.5rem;

    &-block {
      @include mixins.flex-column(initial);

      gap: 0.25rem;

      width: 100%;
      background-color: #f8f8f8;
      padding: 1rem;

      &-item {
        @include mixins.flex-row(center, flex-start);

        &-label {
          @include mixins.font-style(0.875rem, #888b90);

          line-height: 1rem;
        }

        &-value {
          @include mixins.font-style(0.875rem, #1e1e1e, 600);

          line-height: 1rem;
          margin-left: auto;
        }
      }
    }
  }
}
