@use '../../../styles/mixins' as mixins;

.teaser-details {
  position: relative;
  height: 43rem;

  &__title {
    @include mixins.font-style(2rem, #005e3f, 600);

    line-height: 2.75rem;
  }

  &__subtitle {
    @include mixins.font-style(1rem, #293033);

    margin: 1.625rem 0;
    max-width: 19.25rem;
    line-height: 1.5rem;
  }

  &__qr {
    max-width: 9rem;
  }

  &__app {
    right: 0;
    bottom: 0;
    position: absolute;
    max-width: 21.25rem;
  }

  &__info {
    position: absolute;
    left: 0;
    bottom: 5rem;
    text-decoration: none;
  }
}
