@use '../../../styles/mixins' as mixins;

$prefix: '--profile-feed';

.profile-feed {
  @include mixins.flex-column(initial);

  margin-top: 1.5rem;
  gap: 1rem;

  &__title {
    @include mixins.font-style(1rem, var(#{$prefix}-title-color), 600);

    line-height: 1.25rem;
    margin-right: auto;
  }

  &__horizontal {
    @include mixins.flex-column(flex-start);

    width: 100%;
    gap: 1rem;

    &-header {
      @include mixins.flex-row(center, flex-end);

      width: 100%;
      gap: 1rem;

      &-view {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.25rem;
        flex-shrink: 0;
        cursor: pointer;
      }
    }
  }
}
