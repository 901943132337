@use '../../../styles/mixins' as mixins;

$filter-prefix: '--filter';
$input-prefix: '--input';

.filter {
  position: relative;

  &__list {
    @include mixins.flex-column(initial);

    gap: 1.5rem;
    position: absolute;
    left: 0;
    top: 1.5rem;
    padding: 1rem;
    width: 12rem;
    border-radius: 0.25rem;
    background: #fff;
    z-index: 3;
    box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.045755),
      0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.101508);

    &--right-aligned {
      left: unset;
      right: 0;
    }

    &--static {
      position: static;
      width: 100%;
      padding: 0;
      box-shadow: none;
      background: none;
    }

    &-group {
      @include mixins.flex-column(initial);

      gap: 0.5rem;

      &-items {
        @include mixins.flex-column(initial);

        gap: 0.5rem;

        &--no-group-title {
          margin-top: 1rem;
        }

        &--row-alignment {
          @include mixins.flex-row(center, flex-start);

          gap: 1.5rem;
        }
      }

      &-title {
        @include mixins.font-style(0.875rem, var(#{$filter-prefix}-title-color), 600);
      }

      &-item {
        @include mixins.ellipsis();
        @include mixins.font-style(0.875rem, var(#{$filter-prefix}-item-color));

        position: relative;
        padding-left: 1.5rem;
        line-height: 1rem;
        cursor: pointer;

        &--range {
          text-overflow: unset;
          overflow: unset;
          white-space: unset;
        }

        &::before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          border: 0.0625rem solid #b3b3b3;
          background-color: #fff;
          box-sizing: border-box;
        }

        &--multi {
          &::before {
            border-radius: 0.2rem;
          }
        }

        &--checked:not(.filter__list-group-item--multi) {
          &::after {
            @include mixins.position-centered();

            content: '';
            left: 0.5rem;
            top: 0.5rem;
            width: 0.5rem;
            height: 0.5rem;
            background-color: var(#{$filter-prefix}-active-color);
            border-radius: 50%;
          }
        }

        &--checked.filter__list-group-item--multi {
          &::before {
            background-color: var(#{$filter-prefix}-active-color);
          }
        }

        &-icon {
          position: absolute;
          top: 0.15rem;
          left: 0.15rem;
          width: 0.75rem;
          height: 0.75rem;
          color: var(#{$filter-prefix}-active-icon-color);
          pointer-events: none;
        }

        &-hint {
          @include mixins.font-style(0.75rem, #787878);

          word-break: break-word;
          white-space: pre-wrap;
        }

        &-date {
          @include mixins.font-style(0.875rem, var(#{$input-prefix}-text-color));

          margin-top: 0.25rem;
          padding: 0.25rem 0.5rem;
          border-radius: 0.25rem;
          border: 0.0625rem solid;
          width: calc(100% - 1rem);
          box-sizing: border-box;
          border-color: var(#{$input-prefix}-border-color);
          line-height: 1.5rem;

          &:focus,
          &:active {
            background-color: var(#{$input-prefix}-background-focus-color);
            color: var(#{$input-prefix}-text-focus-color);
            border-color: var(#{$input-prefix}-border-focus-color);
          }
        }

        &-dropdown,
        &-input-number {
          margin-top: 0.5rem;
        }
      }
    }
  }
}
