@use '../../../../styles/mixins' as mixins;

$prefix: '--details-block-check-list';

.check-list {
  @include mixins.font-style(1rem, var(#{$prefix}-text-color));

  list-style: none;
  padding-left: 0.5rem;

  &__item {
    @include mixins.flex-row(flex-start, flex-start);

    margin-bottom: 0.5rem;
    line-height: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    &-check {
      position: relative;
      min-width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.25rem;
      border: 0.0625rem solid #b3b3b3;
      background-color: #fff;

      &--checked {
        border-color: var(#{$prefix}-background-color);
        background-color: var(#{$prefix}-background-color);
      }

      &-icon {
        @include mixins.position-centered();

        width: 1rem;
        color: var(#{$prefix}-icon-color);
      }
    }

    &-content {
      padding: 0.2rem 0.5rem 0 0.5rem;

      white-space: pre-wrap;
      word-break: break-word;
    }
  }
}
