@use '../../styles/mixins' as mixins;

$feed-prefix: '--horizontal-feed';
$card-prefix: '--card';

.card {
  @include mixins.flex-column(flex-start, space-between);

  width: calc(
    (var(--content-max-width) - 2 * var(#{$feed-prefix}-space-between-cards)) /
      var(#{$feed-prefix}-cards-per-view)
  );
  height: 10rem;
  border: 0.0625rem solid var(#{$card-prefix}-border-color);
  box-sizing: border-box;
  transition: box-shadow 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
  background-color: var(#{$card-prefix}-background-color);
  cursor: pointer;

  &:hover {
    box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.2);
  }

  &--not-published {
    opacity: 0.5;
  }

  &__teaser {
    position: relative;
    height: 7rem;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;

    &-icon {
      @include mixins.position-centered();
    }

    &--overlay {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(#{$feed-prefix}-overlay-background-color);
      }
    }
  }

  &__posted-time {
    @include mixins.font-style(0.75rem, var(#{$card-prefix}-posted-time-color));

    line-height: 1.125rem;
    padding: 0.5rem;
  }

  &__modal-swiper-slide {
    margin: auto;
    height: 100vh;
  }

  &__modal-preview {
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
  }
}

:global(media-player.card__modal-video) {
  :global(video) {
    height: 22rem;
  }
}
