@use '../../../styles/mixins' as mixins;

$loader-prefix: '--circular-loader';

@include mixins.animation(rotation) {
  0% {
    @include mixins.transform(rotate(0));
  }
  100% {
    @include mixins.transform(rotate(360deg));
  }
}

.loader {
  @include mixins.flex-row();

  gap: 0.5rem;

  &__text {
    @include mixins.font-style(0.875rem, #1e1e1e);
  }

  &__body {
    @include mixins.animation-use(rotation, 1000ms);

    border: 0.25rem solid #ddd;
    border-bottom-color: var(#{$loader-prefix}-fill-color);
    border-radius: 50%;
    box-sizing: border-box;
    flex-shrink: 0;
  }
}
