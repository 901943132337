@use '../../../../styles/mixins' as mixins;

.message-gallery {
  @include mixins.flex-row(flex-start, flex-start);

  width: calc(100% - 2rem);
  padding: 0.5rem 1rem;
  gap: 1rem;
  flex-wrap: wrap;

  &__image {
    width: 3.5rem;
    height: 3.5rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 0.25rem;

    &-wrapper {
      position: relative;
    }
  }

  &__remove {
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
  }

  &__media-placeholder {
    position: relative;
    width: 10rem;
    height: 5rem;
    background-size: cover;
    background-position: center;
    background-color: var(--create-card-file-preview-background-color);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(41, 48, 51, 0.3);
    }

    &-icon {
      @include mixins.position-centered();
    }
  }
}
