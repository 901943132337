@use '../../../styles/mixins' as mixins;

$prefix: '--modal';

.modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;

  &--visible {
    display: block;
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(#{$prefix}-overlay-background-color);
    opacity: 0.5;

    &--preview-mode {
      opacity: 0.95;
    }

    &--opacity {
      opacity: 1;
      background-color: #a9a9ad;
    }
  }

  &__close {
    position: absolute;
    right: -1rem;
    top: -1rem;
    width: 1rem;
    height: 1rem;
    cursor: pointer;

    &:before,
    &:after {
      @include mixins.transform(rotate(45deg));

      position: absolute;
      content: '';
      left: 0.42rem;
      height: 100%;
      width: 0.125rem;
      background-color: var(#{$prefix}-close-color);
    }

    &:after {
      @include mixins.transform(rotate(-45deg));
    }

    &--preview-mode {
      right: 2rem;
      top: 2rem;

      &:before,
      &:after {
        background-color: var(#{$prefix}-preview-close-color);
      }
    }
  }

  &__content {
    @include mixins.position-centered(relative);
    @include mixins.custom-scrollbar();

    width: 42rem;
    max-height: calc(100% - 5rem);
    padding: 1.5rem;
    background-color: var(#{$prefix}-background-color);
    box-shadow: 0 1.5rem 1.5rem -1rem rgba(0, 0, 0, 0.2);

    &--mobile {
      width: calc(100% - 3rem);
    }

    &--preview-mode {
      width: fit-content;
      height: auto;
      max-width: 100%;
      max-height: 100vh;
      background-color: transparent;
      box-shadow: none;
      padding: 0;
    }

    &--align-top {
      @include mixins.transform(translate(-50%, 0));

      top: 1.15rem;
    }

    &--align-bottom {
      @include mixins.transform(translate(-50%, -100%));

      top: 100%;
    }
  }

  &__header {
    position: relative;

    &-title {
      @include mixins.font-style(1rem, var(#{$prefix}-title-color), 600);

      line-height: 1.5rem;
    }
  }

  &__body {
    font-size: 1rem;
    overflow: hidden;

    &-error {
      @include mixins.font-style(1rem, var(--color-danger));

      line-height: 1.25rem;
      margin-top: 1rem;
    }
  }

  &__preview-src {
    position: absolute;
    right: 4rem;
    top: 2rem;
  }
}
