@use '../../../styles/mixins' as mixins;

$prefix: '--latest-story-cards';

.latest-story-cards {
  background-color: var(#{$prefix}-background-color);
  width: 100%;

  &__cards-loader {
    width: calc(100% - 2rem);
    margin: 1rem;
    height: 9rem;
  }

  &__empty {
    @include mixins.font-style(1rem, var(#{$prefix}-text-color));

    position: relative;
    text-align: center;
    padding: 3rem 0 1rem 0;

    &-icon {
      @include mixins.position-centered();

      top: 1.5rem;
    }
  }
}
