$prefix: '--details-block-common';
$link-prefix: '--base-link';

.block {
  margin-bottom: 1.5rem;

  &__paragraph {
    margin-bottom: 1rem;
  }

  &__header-1 {
    margin-bottom: 1.5rem;
  }

  &__header-2 {
    margin-bottom: 1.5rem;
  }

  &__header-3 {
    margin-bottom: 1rem;
  }

  &__header-4 {
    margin-bottom: 1rem;
  }

  &__header-5 {
    margin-bottom: 0.75rem;
  }

  &__header-6 {
    margin-bottom: 0.5rem;
  }

  a {
    text-decoration: none;
    color: var(#{$link-prefix}-text-color);

    &:hover {
      color: var(#{$link-prefix}-text-hover-color);
    }
  }

  mark {
    background: var(#{$prefix}-mark-background-color);
    color: inherit;
  }

  code {
    background: var(#{$prefix}-code-background-color);
    color: inherit;
  }
}
