@use '../../../styles/mixins' as mixins;

$prefix: '--no-content';

@include mixins.animation(blink) {
  0%,
  88%,
  94%,
  100% {
    height: 0.75rem;
    margin-top: 0;
  }
  91%,
  97% {
    height: 0;
    margin-top: 0.375rem;
  }
}

.no-content {
  @include mixins.flex-column(center, center);

  gap: 2rem;
  border: 0.0625rem solid var(#{$prefix}-border-color);
  padding: 3rem;
  box-sizing: border-box;
  min-height: 30rem;
  background-color: var(#{$prefix}-background-color);

  &__page {
    position: relative;
    width: 12.5rem;
    height: 11.875rem;
    background: var(#{$prefix}-face-background-color);

    &-face {
      position: relative;
      margin: 2.5rem auto 0 auto;
      width: 3.5rem;
      height: 2.875rem;
    }

    &-eye {
      @include mixins.animation-use(blink, 3s);

      width: 0.75rem;
      height: 0.75rem;
      background: var(#{$prefix}-face-color);
      border-radius: 50%;
      float: left;

      &:last-of-type {
        float: right;
      }
    }

    &-smile {
      position: absolute;
      width: 1.5rem;
      height: 0.75rem;
      border-top-left-radius: 1.5rem;
      border-top-right-radius: 1.5rem;
      border: 0.25rem solid var(#{$prefix}-face-color);
      border-bottom: 0;
      left: 50%;
      bottom: 0;
      margin-left: -0.75rem;
      box-sizing: border-box;
    }

    &-footer {
      @include mixins.flex-column(flex-start, center);

      position: absolute;
      gap: 0.625rem;
      left: 0;
      bottom: 0;
      width: calc(100% - 1.1rem);
      padding-left: 0.6rem;
      height: 4.375rem;
      margin: 0.25rem;
      background: var(#{$prefix}-background-color);

      &-stripe {
        width: 10.375rem;
        height: 1rem;
        background: var(#{$prefix}-face-background-color);

        &:last-child {
          width: 7.875rem;
        }
      }
    }
  }

  &__message {
    width: 100%;
    max-width: 23.875rem;
    text-align: center;

    &-title {
      @include mixins.font-style(1.25rem, var(#{$prefix}-title-color), 600);

      line-height: 1.75rem;
    }

    &-subtitle {
      @include mixins.font-style(1rem, var(#{$prefix}-subtitle-color));

      line-height: 1.5rem;
      margin-top: 0.5rem;
    }
  }
}
