@use '../../../styles/mixins' as mixins;

.confirmation {
  &__body {
    @include mixins.flex-column(flex-start, flex-start);

    gap: 1rem;

    &-title {
      @include mixins.font-style(1.5rem, #43445b, 600);

      line-height: 1.75rem;
    }

    &-subTitle {
      @include mixins.font-style(1rem, #1e1e1e);

      line-height: 1.25rem;
    }

    &-buttons {
      @include mixins.flex-row(center, flex-end);

      gap: 1rem;
      width: 100%;
    }

    &-error {
      @include mixins.font-style(1rem, var(--color-danger));

      line-height: 1.25rem;
    }
  }
}
