@use '../../../styles/mixins' as mixins;

$prefix: '--media-video';

.player {
  :global(video) {
    max-height: 22rem;
  }

  :global(media-outlet) {
    background-color: transparent;
  }

  &__play-button {
    @include mixins.position-centered();

    width: 48px; //in px for IOS safari
    height: 48px; //in px for IOS safari
    background: rgba(0, 0, 0, 0.8);
    border-radius: 50%;
    z-index: 1;
  }
}

.caption {
  @include mixins.font-style(1rem, var(#{$prefix}-caption-color));

  padding: 0 var(--card-spacing);
  margin-top: 0.5rem;
  line-height: 1.5rem;
  word-break: break-word;
  white-space: pre-wrap;
}
