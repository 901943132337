@use '../../../styles/mixins' as mixins;

$prefix: '--tabs';

.tabs {
  width: 100%;

  &__tab-names {
    @include mixins.flex-row(center, flex-start);

    gap: 1rem;
    padding: 0 1rem;
    border-bottom: 0.125rem solid var(#{$prefix}-border-color);
    background-color: var(#{$prefix}-background-color);

    &--no-background {
      padding: 0;
      background-color: transparent;
    }

    &-item {
      @include mixins.flex-row(center, flex-start);

      &--disabled {
        pointer-events: none;
      }

      &-check {
        display: none;
      }

      &-label {
        @include mixins.font-style(1rem, var(#{$prefix}-label-color));

        position: relative;
        padding: 0.75rem 0;
        line-height: 1.5rem;

        &--clickable {
          cursor: pointer;
        }

        &:hover {
          color: var(#{$prefix}-active-color);
        }
      }

      &-check:checked + &-label {
        color: var(#{$prefix}-active-color);

        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -0.0625rem;
          height: 0.125rem;
          width: 100%;
          background: var(#{$prefix}-active-color);
        }
      }
    }
  }

  &__tab-content {
    @include mixins.flex-column(initial, flex-start);

    height: 100%;
  }
}
