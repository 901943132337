@use '../../../../styles/mixins' as mixins;

$prefix: '--horizontal-feed';

.horizontal-feed {
  @include mixins.flex-column(flex-start);

  width: 100%;
  gap: 1rem;
  margin: 1rem 0;

  &__header {
    @include mixins.flex-row(center, space-between);

    width: 100%;

    &-title {
      @include mixins.font-style(1rem, var(#{$prefix}-title-color), 600);
      @include mixins.ellipsis();

      line-height: 1.25rem;
    }

    &-view {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.25rem;
      flex-shrink: 0;
      cursor: pointer;
    }
  }
}
