@use '../../styles/mixins' as mixins;

.body {
    @include mixins.flex-column(flex-start);

    gap: 1.25rem;

    &__image {
        max-width: 100%;

        &--logo {
            height: 2.5rem;
        }
    }

    &__text {
        @include mixins.flex-column(flex-start);

        gap: 0.25rem;
    }

    &__title {
        @include mixins.font-style(1.25rem, inherit, 600);

        line-height: 1.75rem;
        word-break: break-word;
    }

    &__intro {
        @include mixins.font-style(1rem, inherit);

        line-height: 1.5rem;
        word-break: break-word;
    }

    &__button {
        @include mixins.font-style(1rem, initial, 600);

        padding: 0.5rem 1.5rem;
        border-radius: 0.25rem;
        border: 0;
        line-height: 1.5rem;
        cursor: pointer;
    }
}