.line-clamp {
  position: relative;
  width: 100%;

  &--clickable {
    cursor: pointer;
  }

  &__content {
    overflow: hidden;
    word-break: break-word;
    white-space: pre-wrap;
  }

  &__read-more {
    white-space: nowrap;
    cursor: pointer;
  }
}
