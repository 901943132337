@use '../../../styles/mixins' as mixins;

$prefix: '--check';

.check {
  @include mixins.flex-row(center, flex-start);

  position: relative;
  height: 1rem;
  min-width: 1rem;
  cursor: pointer;

  &--has-label {
    width: calc(100% - 1.5rem);
    padding-left: 1.5rem;
  }

  &--small {
    @include mixins.font-style(0.75rem, #1e1e1e);

    line-height: 1rem;
  }

  &--medium {
    @include mixins.font-style(0.875rem, #1e1e1e);

    line-height: 1.15rem;
  }

  &--large {
    @include mixins.font-style(1rem, #1e1e1e);

    line-height: 1.25rem;
  }

  &__label {
    @include mixins.ellipsis();
  }

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 0.2rem;
    border: 0.0625rem solid #b3b3b3;
    background-color: #fff;
    box-sizing: border-box;
  }

  &--checked {
    &::before {
      background-color: var(#{$prefix}-background-color);
    }
  }

  &__icon {
    position: absolute;
    top: 0.1rem;
    left: 0.15rem;
    width: 0.75rem;
    height: 0.75rem;
    color: white;
  }
}
