@use '../../styles/mixins' as mixins;

$prefix: '--nav-menu';
$link-prefix: '--nav-menu-link';

.menu {
  @include mixins.flex-column(initial);

  gap: 0.5rem;
  list-style: none;
  background-color: var(#{$prefix}-background-color);

  &__item {
    @include mixins.flex-row(center, flex-start);

    gap: 1rem;
    height: 3rem;
    padding: 0 0.75rem;
    text-decoration: none;
    color: var(#{$link-prefix}-text-color);

    &-icon {
      width: 1.5rem;
      height: 1.5rem;
      background-color: var(#{$link-prefix}-text-color);
    }

    &:hover {
      color: var(#{$link-prefix}-text-hover-color);

      .menu__item-icon {
        background-color: var(#{$link-prefix}-text-hover-color);
      }
    }

    &-label {
      @include mixins.font-style(1rem, inherit, 600);
    }

    &--active {
      color: var(#{$link-prefix}-selected-text-color);
      background: var(#{$link-prefix}-selected-background-color);

      .menu__item-icon {
        background-color: var(#{$link-prefix}-selected-text-color);
      }
    }
  }
}
