@use '../../../../styles/mixins' as mixins;

.social-login {
  @include mixins.flex-row();

  width: 100%;
  gap: 1rem;

  &__item {
    padding: 0.475rem;
    text-align: center;
    border: 0.0625rem solid #dadce0;
    border-radius: 0.25rem;
    line-height: 0;
    cursor: pointer;

    &:hover {
      background-color: rgba(66, 133, 244, 0.04);
      border-color: #d2e3fc;
    }
  }

  &__loader {
    width: 2.5rem;
  }
}
