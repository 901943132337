@use '../../../../../styles/mixins' as mixins;

.card {
  @include mixins.flex-row(center, space-between);

  width: 100%;
  gap: 1rem;

  &__fields {
    @include mixins.flex-column(initial);

    max-width: calc(100% - 2.5rem - 1rem);
    gap: 0.25rem;

    &-header {
      @include mixins.flex-row(center, flex-start);

      &-type {
        @include mixins.font-style(0.875rem, #888b90);

        line-height: 1rem;
      }

      &-title {
        @include mixins.font-style(0.875rem, #1e1e1e, 600);
        @include mixins.ellipsis();

        line-height: 1rem;
      }
    }

    &-text {
      @include mixins.font-style(0.875rem, #888b90);
      @include mixins.ellipsis();

      line-height: 1rem;
    }
  }

  &__teaser {
    width: 2.5rem;
    height: 2.5rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 0.5rem;
  }
}

.card-select {
  @include mixins.flex-row(baseline, space-between);

  gap: 1rem;

  &__change-story {
    flex-shrink: 0;
  }
}
