@use '../../../styles/mixins' as mixins;

.bread-crumbs {
  @include mixins.flex-row(center, flex-start);

  position: relative;

  &__wrapper {
    @include mixins.ellipsis();

    overflow-wrap: break-word;
  }

  &__item {
    @include mixins.font-style(1rem, #6d767c, 600);

    line-height: 1.5rem;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #40484d;
    }

    &--active {
      color: #40484d;
      cursor: default;
    }
  }

  &__separator {
    display: inline-flex;
    position: relative;
    top: 0.15rem;
    width: 1rem;
    height: 1rem;
    margin: 0 0.5rem;
  }
}
