@use '../../../styles/mixins' as mixins;

.tags-feed {
  @include mixins.flex-column(initial);

  gap: 1rem;

  &__header {
    @include mixins.flex-row(center, space-between);

    &-title {
      @include mixins.flex-row(center, flex-start);
      @include mixins.font-style(2rem, #43445b, 600);
  
      line-height: 2.3rem;
      white-space: pre-wrap;
      word-break: break-word;
    }

    &-switcher {
      margin-left: auto;
    }
  }
  
  &__content {
    @include mixins.flex-column(initial);
  
    gap: 1.5rem;
    max-width: var(--content-max-width);
    padding-bottom: var(--card-bottom-spacing);
  
    &-cards {
      @include mixins.flex-column(initial);
  
      gap: 1.5rem;
    }
  }
}


