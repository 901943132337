@use '../../../styles/mixins' as mixins;

.general {
  @include mixins.flex-column(initial);

  gap: 1.5rem;
  padding-top: 1rem;

  &__tags {
    @include mixins.flex-column(initial);

    gap: 0.5rem;
    width: 100%;

    &-title {
      @include mixins.font-style(1rem, #1e1e1e);
    }

    &-subTitle {
      @include mixins.font-style(0.875rem, #888b90);
    }

    &-loader {
      @include mixins.flex-row(center, flex-start);

      gap: 0.5rem;
      width: 100%;
    }
  }
}
