@use '../../styles/mixins' as mixins;

.settings {
  @include mixins.flex-column(flex-start);

  gap: 0.5rem;

  &__title {
    @include mixins.font-style(2rem, #43445b, 600);

    line-height: 2.75rem;
  }

  &__subTitle {
    @include mixins.font-style(1rem, #888b90);

    line-height: 1.5rem;
  }
}
