@use '../../../styles/mixins' as mixins;

$prefix: '--error-layout';

.unhandled-error {
  text-align: center;

  &__message {
    @include mixins.font-style(1rem, var(#{$prefix}-message-color));

    line-height: 1.5rem;
    max-width: 21.875rem;
  }
}
