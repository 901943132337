@use '../../../../styles/mixins' as mixins;

$prefix: '--profile-feed';

.create-card {
  @include mixins.flex-row(center, flex-start);

  gap: 0.5rem;
  padding: 1.5rem;
  border: 0.0625rem solid #ebebeb;
  border-radius: 0.5rem;
  background-color: var(#{$prefix}-create-card-background-color);

  &__label {
    @include mixins.font-style(1rem, #787878);

    cursor: default;
  }

  &__icon {
    padding: 1rem;
    background-color: var(#{$prefix}-create-card-icon-background-color);
    border-radius: 0.5rem;
  }

  &__button {
    margin-left: auto;
  }
}
