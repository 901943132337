@use '../../../../styles/mixins' as mixins;

$prefix: '--details-block-audio';

.audio {
  @include mixins.flex-column(flex-start);

  gap: 0.75rem;

  &__caption {
    @include mixins.font-style(1rem, var(#{$prefix}-caption-color));

    line-height: 1.5rem;
    word-break: break-word;
    white-space: pre-wrap;
  }
}
