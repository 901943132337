@use '../../../../../styles/mixins' as mixins;

$prefix: '--comments-input';

.comment-input {
  @include mixins.flex-column(flex-start);

  position: relative;

  &__file {
    display: none;
  }

  &__field {
    @include mixins.font-style(0.875rem, var(#{$prefix}-text-color));

    width: calc(100% - 0.0625rem);
    line-height: 1.375rem;
    background-color: var(#{$prefix}-background-color);
    border: 0;
    overflow: hidden;
    scroll-margin-bottom: 0.625rem;
    scroll-padding-bottom: 3.125rem;

    &::placeholder {
      color: var(#{$prefix}-placeholder-color);
    }

    &--dropzone {
      background-image: url("data:image/svg+xml,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' fill='none' stroke='blue' stroke-width='1' stroke-dasharray='7' stroke-dashoffset='0' stroke-linecap='square' /></svg>");
    }
  }

  &__icons {
    @include mixins.flex-row(center, flex-start);

    width: calc(100% - 2rem);
    padding: 0 1rem;
    gap: 0.75rem;
    margin-bottom: 0.5rem;

    &-send,
    &-loader {
      margin-left: auto;
    }
  }

  &__emoji-picker {
    position: absolute;
    top: calc(100% + 1rem);
    left: -1rem;
    z-index: 999;
  }
}
