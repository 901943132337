@use '../../../../../../styles/mixins' as mixins;

$prefix: '--details-block-embed';

.embed-link-placeholder {
  @include mixins.flex-row(flex-start, space-between);

  padding: 1rem;
  border: 0.0625rem solid var(#{$prefix}-border-color);
  transition: border-color 0.3s;

  &:hover {
    border-color: var(#{$prefix}-border-hover-color);
  }

  &__icon {
    padding: 1rem;
    background: var(--card-overlay-color);
  }
}
