@use '../../../../styles/mixins' as mixins;

$prefix: '--media-audio';

.trigger {
  @include mixins.flex-row(center, flex-start);

  width: calc(100% - 1.5rem);
  gap: 0.75rem;
  padding: 0.75rem;
  background-color: var(#{$prefix}-background-color);
  box-shadow: none;

  &__reset {
    margin-left: auto;
  }
}

.caption {
  @include mixins.font-style(0.875rem, var(#{$prefix}-caption-color));

  margin-top: 0.5rem;
  line-height: 1.5rem;
  word-break: break-word;
  white-space: pre-wrap;
}
