@use '../../../styles/mixins' as mixins;

.create-story {
  @include mixins.flex-column(initial, flex-start);

  gap: 1rem;

  &__title {
    @include mixins.font-style(1.25rem, #43445b, 600);

    line-height: 1.75rem;
  }

  &__subTitle {
    @include mixins.font-style(0.875rem, #787878);

    line-height: 1.25rem;
  }

  &__content {
    @include mixins.flex-column(initial, flex-start);

    gap: 1rem;
    padding: 1.5rem;
    border: 0.0625rem solid #ebebeb;

    &-fields {
      @include mixins.flex-column(initial, flex-start);

      gap: 0.5rem;

      &-title,
      &-intro {
        padding: 0;
        border: 0;
      }

      &-title {
        @include mixins.font-style(1.5rem, #43445b, 600);

        line-height: 2rem;
      }

      &-intro {
        @include mixins.font-style(1rem, #1e1e1e);

        line-height: 1.5rem;
      }
    }
  }

  &__buttons {
    @include mixins.flex-row(center, flex-end);

    gap: 1rem;

    &-settings {
      margin-right: auto;
    }
  }
}

:global(.story-disabled) {
  opacity: 0.5;
  pointer-events: none;
}
