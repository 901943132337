@use '../../styles/mixins' as mixins;

$prefix: '--story-tab';

.story-tab-list {
  @include mixins.flex-column(initial, flex-start);

  gap: 1rem;

  &__header {
    @include mixins.flex-row(center, flex-start);
  }

  &__title {
    @include mixins.font-style(2rem, var(#{$prefix}-headline-color), 600);

    line-height: 2.75rem;
  }

  &__buttons {
    @include mixins.flex-row(center, flex-start);

    gap: 1rem;
    margin-left: auto;
  }

  &__create-story {
    position: relative;
  }
}
