@use '../../../../styles/mixins' as mixins;

.file-preview {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.0625rem;

  &__teaser {
    position: relative;
    width: calc((100% - 0.125rem) / 3);
    height: 10rem;

    &--ghost {
      opacity: 0.35;
    }

    &--dragging *,
    &--loading {
      pointer-events: none;
    }

    &-url {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      transition: background-image 1s ease-in-out;
      background-color: var(--create-card-file-preview-background-color);

      &--pointer {
        cursor: pointer;
      }

      &--move {
        cursor: move;
      }

      &--overlay:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(41, 48, 51, 0.3);
      }
    }

    &-icon {
      @include mixins.position-centered();
    }
  }

  &__actions {
    @include mixins.flex-row(flex-start, flex-start);

    gap: 0.5rem;
    position: absolute;
    right: 0.625rem;
    top: 0.625rem;

    &-remove,
    &-settings {
      position: relative;
      width: 2rem;
      height: 2rem;
      background: #fff;
      border: 0.0625rem solid #ebebeb;
      border-radius: 50%;
      box-sizing: border-box;
      box-shadow: 0 0 0.2rem #293033;
      opacity: 0.5;
      transition: opacity 0.3s;
      cursor: pointer;

      &--active,
      &:hover {
        opacity: 1;
      }
    }

    &-remove::before,
    &-remove::after {
      @include mixins.transform(rotate(45deg));

      position: absolute;
      content: '';
      left: 0.9rem;
      top: 0.35rem;
      height: 1.2rem;
      width: 0.1rem;
      background-color: #787878;
    }

    &-remove:after {
      @include mixins.transform(rotate(-45deg));
    }
  }

  &__teaser-modal {
    max-width: calc(100vw - 10rem);
    max-height: calc(100vh - 0.25rem);
  }
}
