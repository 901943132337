@use '../../../../../styles/mixins' as mixins;

$prefix: '--create-card';

.create-card {
  @include mixins.flex-column(flex-start, flex-start);

  width: 100%;
  gap: 1rem;

  &__post-in {
    @include mixins.flex-row(center, flex-start);

    gap: 0.25rem;
    width: 100%;

    &-label {
      @include mixins.font-style(1rem, var(#{$prefix}-post-in-color), 600);
    }
  }

  &__buttons {
    @include mixins.flex-row(center, flex-end);

    gap: 1.5rem;
    width: 100%;
  }
}
