@use '../../../../../styles/mixins' as mixins;

$prefix: '--card';
$wrapper-prefix: '--latest-story-cards';

.card {
  @include mixins.flex-row(flex-start, flex-start);

  position: relative;
  width: calc(100% - 2rem);
  padding: 1rem;
  gap: 0.75rem;
  transition: background-color 0.3s;
  cursor: pointer;

  &:after {
    content: '';
    bottom: 0;
    position: absolute;
    height: 0.0625rem;
    width: calc(100% - 2rem);
    border-bottom: 0.0625rem solid var(#{$prefix}-border-color);
  }

  &:last-child:after {
    border-bottom: 0;
  }

  &:hover {
    background: var(#{$wrapper-prefix}-background-hover-color);
  }

  &__content {
    @include mixins.flex-column(flex-start, flex-start);

    width: 100%;
    gap: 0.25rem;

    &--with-place-for-side-teaser {
      max-width: calc(100% - 0.75rem - 3.75rem); //100% - flex gap - image width
    }
  }

  &__teaser {
    width: 100%;
    position: relative;
    overflow: hidden;

    &-background {
      width: 100%;
      height: 9rem;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 0.25rem;

      -webkit-filter: blur(0.25rem) brightness(75%);
      filter: blur(0.25rem) brightness(75%);
    }

    &-image {
      @include mixins.position-centered();

      max-height: 8.5rem;
    }

    &-overlay {
      @include mixins.flex-column();

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(41, 48, 51, 0.3);

      &--no-image-loaded {
        position: relative;
        background-color: var(#{$prefix}-overlay-color);
        width: 3.75rem;
        height: 3.75rem;

        &.card__teaser-overlay--wide {
          width: 100%;
          height: 9rem;
        }
      }
    }
  }

  &__side-teaser {
    position: relative;

    &-image {
      min-width: 3.75rem;
      height: 3.75rem;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__posted-time {
    @include mixins.font-style(0.75rem, var(#{$prefix}-posted-time-color));

    line-height: 1.125rem;
  }
}
