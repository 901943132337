@use '../../../../styles/mixins' as mixins;

.create-story-settings {
  @include mixins.flex-column(initial, flex-start);

  gap: 1rem;

  &__tags {
    @include mixins.flex-column(initial, flex-start);

    gap: 1rem;
    margin: 1rem 0 15rem 0;
  }

  &__sync {
    margin: 1rem 0 15rem 0;
  }
}
