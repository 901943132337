@use '../../../styles/mixins' as mixins;

.schedule-input {
  @include mixins.flex-column(flex-start);

  width: 22.6rem;
  padding: 1.5rem;
  gap: 0.5rem;

  &__title {
    @include mixins.font-style(1.25rem, #43445b, 600);
  }

  &__subTitle {
    @include mixins.font-style(1rem, #888b90);

    line-height: 1.25rem;
  }

  &__picker {
    @include mixins.flex-row(flex-start);

    position: relative;
    border: 0.0625rem solid #aeaeae;
    border-radius: 0.35rem 0.35rem 0 0;
  }

  &__icon {
    position: relative;
    top: 0.2rem;
  }
}

:global(#schedule-input .react-datepicker__time-list-item) {
  padding: 0.1rem 0;
  line-height: 1.875rem;
}

:global(#schedule-input .react-datepicker__time-list) {
  @include mixins.custom-scrollbar();

  height: 17rem !important; //to override inline styles
}
