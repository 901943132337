@use '../../../../../styles/mixins' as mixins;

$prefix: '--details-block-header';

:global(.ce-header) {
  word-break: break-word;
  white-space: pre-wrap;
}

:global(h1.ce-header) {
  @include mixins.font-style(2.5rem, var(#{$prefix}-1-color), 600);

  line-height: 3rem;
  margin-bottom: 1.5rem;
}

:global(h2.ce-header) {
  @include mixins.font-style(2.25rem, var(#{$prefix}-2-color), 600);

  line-height: 2.75rem;
  margin-bottom: 1.5rem;
}

:global(h3.ce-header) {
  @include mixins.font-style(1.75rem, var(#{$prefix}-3-color), 600);

  line-height: 2.25rem;
  margin-bottom: 1rem;
}

:global(h4.ce-header) {
  @include mixins.font-style(1.5rem, var(#{$prefix}-4-color), 600);

  line-height: 2rem;
  margin-bottom: 1rem;
}

:global(h5.ce-header) {
  @include mixins.font-style(1.25rem, var(#{$prefix}-5-color));

  line-height: 1.75rem;
  margin-bottom: 0.75rem;
}

:global(h6.ce-header) {
  @include mixins.font-style(1rem, var(#{$prefix}-6-color), 600);

  line-height: 1.5rem;
  margin-bottom: 0.5rem;
}
