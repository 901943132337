@use '../../../styles/mixins' as mixins;

$prefix: '--dropdown-menu';

.multi-step-filter {
  @include mixins.flex-column(initial);

  gap: 1rem;

  &__list {
    @include mixins.flex-column(initial, flex-start);
    @include mixins.custom-scrollbar();

    width: 20rem;
    gap: 1rem;
    padding: 1rem;
    max-height: 17rem;
    overflow: auto;

    &-item {
      @include mixins.flex-row(center, flex-start);
      @include mixins.font-style(1rem, var(#{$prefix}-text-color));

      width: 100%;
      text-decoration: none;

      &--with-child {
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  &__value {
    @include mixins.flex-row(center, flex-start);

    gap: 1rem;
    flex-wrap: wrap;
  }
}
