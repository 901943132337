@use '../../../styles/mixins' as mixins;

.story-tags {
  @include mixins.font-style(0.75rem, #787878);
  @include mixins.flex-row(center, flex-start);

  line-height: 1.125rem;
  flex-wrap: wrap;
  gap: 0.5rem;
}
