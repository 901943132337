@use '../../styles/mixins' as mixins;

.deep-link-modal {
  @include mixins.flex-column(initial);

  gap: 0.875rem;

  &__header {
    @include mixins.font-style(1rem, #43445b, 600);

    margin: 0 auto;
  }

  &__option {
    @include mixins.flex-row(center, flex-start);

    //! TODO WTF

    justify-content: space-between;

    &-app {
      @include mixins.flex-row(center, flex-start);

      gap: 0.5rem;
    }

    &-button {
      width: 10rem;
    }
  }
}
