@use '../../../../styles/mixins' as mixins;

$prefix: '--details-block-callout';

.callout {
  @include mixins.flex-row(flex-start, flex-start);
  @include mixins.font-style(1rem, var(#{$prefix}-text-color));

  padding: 1rem;
  background: var(#{$prefix}-background-color);
  line-height: 1.5rem;
}
