@use '../../../../styles/mixins' as mixins;

$prefix: '--details-block-list';

.list {
  @include mixins.font-style(1rem, var(#{$prefix}-text-color));

  margin: 0;

  &__item {
    margin-bottom: 0.5rem;
    line-height: 1.5rem;
    white-space: pre-wrap;
    word-break: break-word;
  }

  & .ul,
  & .ol {
    margin: 0.5rem 0 0 0;
  }

  &.ul {
    list-style-type: disc;
    padding-left: 1.5rem;
  }

  &.ol {
    counter-reset: list-counter;
    padding-left: 0.5rem;
  }

  &.ol .list__item {
    list-style: none;
    counter-increment: list-counter;

    &:before {
      content: attr(data-counter);
    }
  }
}
