@use '../../../../../styles/mixins' as mixins;

$link-prefix: '--base-link';

.code {
  @include mixins.flex-row(flex-start);

  width: 100%;
  gap: 1rem;

  &__digit {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.25rem;
    border: 0.0625rem solid #6d767c;
    text-align: center;
    box-sizing: border-box;

    &:focus {
      border: 2px solid var(#{$link-prefix}-text-hover-color);
    }
  }
}
