@use '../../../../styles/mixins' as mixins;

$prefix: '--details-block-quote';

.quote {
  &__image {
    margin-bottom: 0.75rem;
  }

  &__text {
    @include mixins.font-style(1.25rem, var(#{$prefix}-text-color), 600);

    margin-bottom: 0.5rem;
    line-height: 1.75rem;
    white-space: pre-wrap;
    word-break: break-word;
  }

  &__caption {
    @include mixins.font-style(1rem, var(#{$prefix}-caption-color));

    line-height: 1.5rem;
    white-space: pre-wrap;
    word-break: break-word;
  }
}
