@use '../../../../styles/mixins' as mixins;

$prefix: '--card';
$content-prefix: '--card-content';
$link-prefix: '--base-link';

.card-collapsed-header {
  @include mixins.flex-row(center, flex-start);

  gap: 1rem;
  position: relative;
  border: 0.0625rem solid var(#{$prefix}-border-color);
  padding: 0 1rem;
  height: 4.5rem;
  cursor: pointer;
  user-select: none;
  background-color: var(#{$prefix}-background-color);

  &--opened {
    border-bottom: 0;
  }

  &__type {
    width: 7rem;
  }

  &__description {
    width: calc(100% - 7rem - 4rem);

    &--has-preview {
      width: calc(100% - 7rem - 4rem - 6rem);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      margin-left: -1rem;
      width: 0.0625rem;
      height: 100%;
      background-color: var(#{$prefix}-border-color);
    }

    &-heading {
      @include mixins.font-style(1rem, var(#{$content-prefix}-text-color));
      @include mixins.ellipsis();

      line-height: 1.25rem;

      &--bold {
        color: var(#{$content-prefix}-headline-color);
        font-weight: bold;
      }

      a {
        text-decoration: none;
        color: var(#{$link-prefix}-text-color);

        &:hover {
          color: var(#{$link-prefix}-text-hover-color);
        }
      }
    }

    &-posted-time {
      @include mixins.font-style(1rem, var(#{$prefix}-posted-time-color));

      line-height: 1.25rem;
      flex-shrink: 0;
    }

    &-preview {
      width: 6rem;
      height: calc(100% - 1rem);
      background-size: cover;
      background-position: center;
    }
  }

  &__arrow {
    position: absolute;
    right: 1rem;
    width: 1.25rem;
    height: 1.25rem;
    color: #b3b3b3;

    &--up {
      @include mixins.transform(rotate(180deg));

      color: var(#{$link-prefix}-text-color);
    }
  }
}
