@use '../../../../styles/mixins' as mixins;

$size: 16rem;

.comment-attachments {
  position: relative;
  margin-left: 1rem;

  &--preview {
    margin-left: 0;
  }

  &__image-wrapper {
    @include mixins.flex-column(flex-start);

    max-width: $size;
    max-height: $size;
    overflow: hidden;
  }

  &__remove {
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
  }

  &__video {
    max-width: $size;

    &-placeholder {
      width: $size;
      height: 0.5 * $size;
      background-size: cover;
      background-position: center;
      background-color: var(--create-card-file-preview-background-color);

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(41, 48, 51, 0.3);
      }

      &-icon {
        @include mixins.position-centered();
      }
    }
  }
}
