@use '../../../styles/mixins' as mixins;

$prefix: '--story-tab';

.story-list {
  @include mixins.flex-column(initial, flex-start);

  gap: 1.5rem;

  &__item {
    position: relative;
    border: 0.0625rem solid var(#{$prefix}-border-color);
    box-sizing: border-box;
    transition: 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-property: background-color, box-shadow;
    background-color: var(#{$prefix}-background-color);

    &-content {
      @include mixins.flex-column(initial, flex-start);

      gap: 0.75rem;
      padding: 0.75rem;
    }

    &-drag-icon {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 50%;
      margin-left: -0.4375rem;
      transition: opacity 0.35s;
      cursor: move;
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;

      &:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
      }
    }

    &:hover {
      box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.2);

      .story-list__item-drag-icon {
        opacity: 1;
      }
    }

    &-top {
      @include mixins.flex-row();

      gap: 1rem;
      cursor: pointer;

      &-image-wrapper {
        @include mixins.flex-row();

        width: 7.5rem;
        max-height: 5rem;
        flex-shrink: 0;
        overflow: hidden;
      }

      &-image {
        max-width: 100%;
        object-fit: cover;
        transform-origin: 50% 50%;
        transition: transform 0.25s ease-in-out;
      }

      &-description {
        @include mixins.flex-column(initial, center);

        gap: 0.25rem;
        width: 100%;
      }

      &-status,
      &-title,
      &-subTitle {
        word-break: break-word;
        white-space: pre-wrap;
      }

      &-status {
        @include mixins.font-style(0.75rem, var(--color-danger));

        position: relative;
        padding-left: 1rem;
        line-height: 1.125rem;

        &:before {
          position: absolute;
          content: '';
          left: 0;
          top: 0.15rem;
          width: 0.5rem;
          height: 0.5rem;
          background-color: #fcecec;
          border-radius: 50%;
          border: 0.0625rem solid var(--color-danger);
        }

        &--published {
          color: #0da068;

          &:before {
            background-color: #c3f7df;
            border-color: #0da068;
          }
        }
      }

      &-title {
        @include mixins.font-style(1.25rem, var(#{$prefix}-title-color), 600);

        line-height: 1.75rem;
      }

      &-subTitle {
        @include mixins.font-style(0.875rem, var(#{$prefix}-subtitle-color));

        line-height: 1.25rem;
      }

      &:hover {
        .story-list__item-top-title {
          color: var(#{$prefix}-title-hover-color);
        }

        .story-list__item-top-subTitle {
          color: var(#{$prefix}-subtitle-hover-color);
        }
      }
    }

    &-bottom {
      @include mixins.flex-row(center, flex-start);

      gap: 1.5rem;
    }

    &--dragging {
      background-color: #f3f3f3;
    }

    &--dragging * {
      pointer-events: none;
    }

    &--ghost {
      opacity: 0.5;
      border-color: #488ed8;
    }
  }

  &__loader {
    @include mixins.flex-column(initial, flex-start);

    gap: 1.5rem;
  }
}
