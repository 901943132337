@use '../../../styles/mixins' as mixins;

$prefix: '--dropdown-menu';

.dropdown-menu {
  @include mixins.flex-column(initial);

  background-color: var(#{$prefix}-background-color);

  &__item,
  &__item--small {
    @include mixins.ellipsis();

    width: calc(100% - 1rem);
    padding: 0 0.5rem;
    cursor: pointer;

    &:hover {
      background-color: var(#{$prefix}-item-background-hover-color);
    }
  }

  &__item {
    @include mixins.font-style(1rem, var(#{$prefix}-text-color));

    height: 3rem;
    line-height: 3rem;
  }

  &__item--small {
    @include mixins.font-style(0.875rem, var(#{$prefix}-text-color), 600);

    height: 2rem;
    line-height: 2rem;
  }
}
