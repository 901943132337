@use '../../../../styles/mixins' as mixins;

$prefix: '--notifications';

.content {
  @include mixins.flex-column(flex-start);

  &__title {
    @include mixins.font-style(1rem, var(#{$prefix}-primary-text-color));

    line-height: 1.5rem;

    &-author {
      font-weight: 600;
      word-break: break-word;
      white-space: pre-wrap;
    }
  }

  &__answer {
    @include mixins.font-style(0.875rem, var(#{$prefix}-primary-text-color));

    line-height: 1.25rem;
    word-break: break-word;
    white-space: pre-wrap;
  }

  &__comment {
    @include mixins.font-style(0.875rem, var(#{$prefix}-secondary-text-color));

    position: relative;
    padding-left: 0.5rem;
    line-height: 1.25rem;
    word-break: break-word;
    white-space: pre-wrap;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 0.125rem;
      height: 100%;
      background-color: var(#{$prefix}-comment-line-color);
    }
  }
}
