@use '../../styles/mixins' as mixins;

$prefix: '--story-feed';

.feed-items {
  @include mixins.flex-column(initial);

  gap: 1.5rem;

  &__header {
    @include mixins.flex-row(center, space-between);

    &-breadCrumbs {
      width: calc(100% - 5.5rem);
    }
  }

  &__story {
    @include mixins.flex-column(initial);

    &-content {
      @include mixins.flex-column(initial);

      gap: 1rem;
      // background-color: #f8f8f8;
      background-color: #fff;
      padding: 1.5rem;
    }

    &-author {
      @include mixins.flex-row(center, flex-start);

      gap: 0.5rem;
      cursor: pointer;

      &-info {
        @include mixins.flex-column(initial);

        &-label {
          @include mixins.font-style(0.875rem, #787878);

          line-height: 1.25rem;
        }

        &-name {
          @include mixins.font-style(0.875rem, #43445b, 600);

          line-height: 1.25rem;
          word-break: break-word;
          white-space: pre-wrap;
        }
      }
    }

    &-headlines {
      @include mixins.flex-column(initial);

      gap: 0.25rem;

      &-title {
        @include mixins.font-style(1.5rem, var(#{$prefix}-title-color), 600);

        line-height: 2rem;
        white-space: pre-wrap;
        word-break: break-word;
      }

      &-subTitle {
        @include mixins.font-style(1rem, var(#{$prefix}-subtitle-color));

        line-height: 1.25rem;
        white-space: pre-wrap;
        word-break: break-word;
      }
    }

    &-buttons {
      @include mixins.flex-row(center, flex-start);

      gap: 1.5rem;

      &-menu {
        position: relative;
        margin-left: auto;
      }
    }
  }
}
