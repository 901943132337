@use '../../../styles/mixins' as mixins;

$prefix: '--input';

.text-area-field {
  position: relative;
  width: 100%;

  &--hidden {
    display: none;
  }

  &__label {
    @include mixins.font-style(0.75rem, var(#{$prefix}-label-color));

    display: block;
    margin-bottom: 0.25rem;
    line-height: 1rem;
    cursor: pointer;

    &--disabled {
      pointer-events: none;
    }
  }

  &__value {
    @include mixins.font-style(1rem, var(#{$prefix}-text-color));

    width: 100%;
    border-color: var(#{$prefix}-border-color);
    box-sizing: border-box;
    padding: 1rem;
    border-radius: 0.25rem;
    resize: none;
    overflow: hidden;

    &:focus,
    &:active {
      background-color: var(#{$prefix}-background-focus-color);
      color: var(#{$prefix}-text-focus-color);
      border-color: var(#{$prefix}-border-focus-color);
    }
  }

  &__counter {
    @include mixins.font-style(0.75rem, #b3b3b3);

    width: 100%;
    text-align: right;
  }

  &__error {
    @include mixins.font-style(0.75rem, var(#{$prefix}-error-message-color));

    position: absolute;
    left: 0;
    bottom: 0;

    &-icon {
      position: absolute;
      right: 1rem;
      top: 2.25rem;

      &--without-label {
        top: 1rem;
      }
    }
  }
}

textarea.text-area-field__value--disabled {
  cursor: default;
  pointer-events: none;
  color: #b3b3b3;
}

textarea.text-area-field__value--error {
  border-color: var(#{$prefix}-error-message-color);
}
