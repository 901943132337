@use '../../../styles/mixins' as mixins;

$upload-prefix: '--profile-upload';

.avatar-upload {
  @include mixins.flex-row();

  &__wrapper {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 2rem;
    top: calc(100% - 2rem);
    background-color: var(#{$upload-prefix}-overlay-color);
    opacity: 0.8;
    cursor: pointer;

    &-icon {
      @include mixins.position-centered();
    }
  }

  &__file {
    display: none;
  }

  &__add-or-delete {
    @include mixins.font-style(0.875rem, var(#{$upload-prefix}-label-color), 600);

    margin-top: 0.5rem;
    border: 0;
    background: none;
    cursor: pointer;

    &:hover {
      color: var(#{$upload-prefix}-label-hover-color);
    }
  }

  &__crop {
    margin: 1rem 0;

    &-buttons {
      @include mixins.flex-row();

      width: 100%;
      gap: 1rem;
    }
  }
}
