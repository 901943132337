@use '../../../../styles/mixins' as mixins;

.file-error {
  @include mixins.flex-column();

  gap: 1.5rem;
  padding: 3rem 1.5rem;
  background-color: #f8f8f8;

  &__message {
    @include mixins.font-style(1rem, #888b90);

    line-height: 1.25rem;
    text-align: center;
  }
}
