@use '../../../../../styles/mixins' as mixins;

.templates {
  @include mixins.flex-column(initial);

  gap: 1rem;

  &__title {
    @include mixins.font-style(1.25rem, #43445b, 600);
  }

  &__list {
    @include mixins.custom-scrollbar();
    @include mixins.flex-row(flex-start, flex-start);

    flex-wrap: wrap;
    max-height: calc(100vh - 11rem);
    gap: 1rem;

    &-item {
      @include mixins.flex-column(initial, flex-start);

      width: calc(100% / 3 - 0.75rem);
      padding: 0.5rem;
      height: 15rem;
      background-color: #f8f8f8;
      border: 0.0625rem solid #d7dbdd;
      box-sizing: border-box;
      gap: 0.25rem;
      cursor: pointer;

      &:hover {
        border-color: var(--base-link-text-hover-color);
      }

      &--selected {
        border-color: var(--base-link-text-color);
      }

      &-image {
        height: 8rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        flex-shrink: 0;
      }

      &-name {
        @include mixins.font-style(0.875rem, #293033, 600);
        @include mixins.ellipsis();

        line-height: 1.25rem;
      }

      &-text {
        @include mixins.font-style(0.875rem, #6d767c);

        line-height: 1.25rem;
      }
    }
  }

  &__button {
    margin-left: auto;
  }
}
