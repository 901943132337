@use '../../../styles/mixins' as mixins;

.switch {
  @include mixins.flex-row();

  cursor: pointer;
  gap: 1rem;

  &__container {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 1.5rem;
    margin-left: auto;
  }

  &__input {
    display: none;

    &:checked + .switch__slide {
      background-color: var(--switch-color);
    }

    &:checked + .switch__slide:before {
      translate: (1.5rem);
    }
  }

  &__slide {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #c7ccd1;
    border-radius: 2rem;
    transition: 0.4s;
    cursor: pointer;

    &:before {
      position: absolute;
      content: '';
      width: 1rem;
      height: 1rem;
      bottom: 0.25rem;
      left: 0.25rem;
      transition: 0.4s;
      background-color: #fff;
      border-radius: 50%;
    }
  }
}
