@use '../../../styles/mixins' as mixins;

$prefix: '--user-directory';

.user {
  @include mixins.flex-row(center, flex-start);

  gap: 0.5rem;
  padding: 0.875rem;
  background-color: var(#{$prefix}-item-color);
  border: 0.0625rem solid #ebebeb;

  &:hover {
    background-color: var(#{$prefix}-item-hover-color);
    cursor: pointer;
  }

  &__info {
    @include mixins.flex-column(flex-start, center);

    max-width: 15rem;

    &-name,
    &-position {
      word-break: break-word;
      white-space: pre-wrap;
    }

    &-name {
      @include mixins.font-style(1rem, var(#{$prefix}-name-color), 600);

      line-height: 1.5rem;
    }

    &-position {
      @include mixins.font-style(0.875rem, var(#{$prefix}-position-color));

      line-height: 1.25rem;
    }
  }

  &__links {
    @include mixins.flex-row(center, flex-start);

    gap: 0.5rem;
    margin-left: auto;
    flex-shrink: 0;
  }
}
