@use '../../../styles/mixins' as mixins;

.create-notification {
  @include mixins.flex-column(initial, flex-start);

  gap: 1rem;

  &__content {
    @include mixins.flex-column(initial, flex-start);
    @include mixins.custom-scrollbar();

    gap: 1rem;
    padding: 1.5rem;
    border: 0.0625rem solid #ebebeb;
    max-height: calc(100vh - 15rem);
    overflow-x: hidden;
  }

  &__title {
    @include mixins.font-style(1.25rem, #43445b, 600);

    line-height: 1.75rem;
  }

  &__error {
    @include mixins.font-style(0.75rem, var(--color-danger));
  }

  &__colors {
    @include mixins.flex-row(center, flex-start);

    gap: 1rem;
    margin-bottom: 0.5rem;

    &-item {
      width: 50%;
    }
  }

  &__buttons {
    @include mixins.flex-row(center, flex-end);

    gap: 1rem;

    &-prio {
      margin-right: auto;
      word-break: break-word;
      white-space: pre-wrap;
    }
  }

  &__pushCard {
    position: relative;

    &-cancel {
      position: absolute;
      right: 4.4rem;
      top: -0.8rem;
      width: 2rem;
      height: 2rem;
      background: #fff;
      border: 0.0625rem solid #ebebeb;
      border-radius: 50%;
      box-sizing: border-box;
      box-shadow: 0 0 0.2rem #293033;
      cursor: pointer;

      &::before,
      &::after {
        @include mixins.transform(rotate(45deg));

        position: absolute;
        content: '';
        left: 0.9rem;
        top: 0.35rem;
        height: 1.2rem;
        width: 0.1rem;
        background-color: #787878;
      }

      &:after {
        @include mixins.transform(rotate(-45deg));
      }
    }
  }
}
