@use '../../../../../styles/mixins' as mixins;

$prefix: '--card-content';

.source,
.title,
.subTitle,
.text {
  width: 100%;
  overflow-wrap: break-word;
  line-height: 1.25rem;
  white-space: pre-wrap;
}

.source {
  @include mixins.font-style(0.75rem, var(#{$prefix}-source-color), 600);
  @include mixins.ellipsis();

  line-height: 1.125rem;
}

.title {
  @include mixins.font-style(0.875rem, var(#{$prefix}-headline-color), 600);
}

.subTitle {
  @include mixins.font-style(0.875rem, var(#{$prefix}-sub-headline-color));
}

.text {
  @include mixins.font-style(0.875rem, var(#{$prefix}-text-color));

  a {
    text-decoration: none;
    color: var(#{$prefix}-text-color);
  }

  //needed to simulate line breaks
  //same like line-height, needed for LineClamp component to calculate rows properly
  p:not(:first-child) {
    margin-top: 1.25rem;
  }
}

.read-more {
  @include mixins.font-style(0.875rem, var(#{$prefix}-text-color));

  line-height: 1.25rem;
}

.title-icon {
  display: inline-flex;
  position: relative;
  top: 0.15rem;
}
