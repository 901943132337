@use '../../../../../styles/mixins' as mixins;

.forward-to-chat {
  @include mixins.flex-column(initial);

  gap: 1rem;

  &__title {
    @include mixins.font-style(1rem, #43445b, 600);
  }

  &__no-data {
    @include mixins.flex-column(center, initial);

    gap: 0.5rem;
    padding: 2rem 1rem 0 1rem;

    &-label {
      @include mixins.font-style(1rem, #787878);
    }
  }

  &__list {
    @include mixins.flex-column(initial, flex-start);
    @include mixins.custom-scrollbar();

    gap: 0.5rem;
    margin-top: 0.5rem;
    max-height: 26rem;

    &-item {
      @include mixins.flex-row(center, flex-start);

      gap: 0.5rem;

      &-name {
        @include mixins.font-style(1rem, #293033, 600);
        @include mixins.ellipsis();

        line-height: 1.25rem;
        cursor: default;
      }
    }
  }

  &__input {
    @include mixins.custom-scrollbar();

    font-size: 0.875rem;

    max-height: 5rem;
    padding: 1rem 1rem 0 1rem;
    border: 0;

    &-wrapper {
      position: relative;
      border: 0.0625rem solid var(--input-border-color);
      border-radius: 0.25rem;
    }

    &-icons {
      @include mixins.flex-row(center, flex-start);

      width: calc(100% - 2rem);
      padding: 0 1rem;
      gap: 0.75rem;
      margin-bottom: 0.5rem;

      &-send,
      &-loader {
        margin-left: auto;
      }
    }
  }

  &__emoji-picker {
    position: absolute;
    bottom: 5rem;
    left: 2.5rem;
    z-index: 999;
  }
}
