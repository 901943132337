@use '../../../../../styles/mixins' as mixins;

$prefix: '--card-thread';

.thread {
  @include mixins.flex-column(flex-start, flex-start);

  position: relative;
  gap: 0.125rem;
  width: calc(100% - 0.75rem);
  padding: 0.375rem;
  background: var(#{$prefix}-background-color);
  border-radius: 0.25rem 0.25rem 0 0.25rem;

  &:after {
    content: '';
    position: absolute;
    top: calc(100% - 0.6rem);
    right: 0;
    width: 0;
    height: 0;
    border: 0.6rem solid black;
    border-color: transparent var(#{$prefix}-background-color) transparent transparent;
  }

  &__discussion {
    @include mixins.font-style(0.75rem, var(#{$prefix}-discussion-color));

    line-height: 1.125rem;
  }

  &__title {
    @include mixins.font-style(0.875rem, var(#{$prefix}-title-color), 600);

    width: 100%;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    line-height: 1.25rem;
  }
}
