@use '../../../../../styles/mixins' as mixins;

.verification {
  @include mixins.flex-column(flex-start);

  width: 100%;
  gap: 0.5rem;

  &__title {
    @include mixins.font-style(0.75rem, #293033);

    line-height: 1.25rem;
  }

  &__link {
    margin: 0 auto;
  }

  &__hint {
    @include mixins.font-style(0.75rem, #787878);

    margin: -1rem auto 0 auto;
  }
}
