@use '../../../styles/mixins' as mixins;

.badge-list {
  @include mixins.flex-column(initial);

  width: 100%;
  gap: 0.5rem;

  &__items {
    @include mixins.flex-row(center, flex-start);
    @include mixins.custom-scrollbar();

    gap: 0.5rem;
    flex-wrap: wrap;
    max-height: 12rem;
    overflow: auto;
  }

  &--with-create {
    width: calc(100% - 1rem);
    padding: 0.5rem;
    border: 1px solid #aeb1b5;
    background-color: #fff;
    border-radius: 0.25rem;
  }

  &__input {
    width: calc(100% - 1rem);
    padding: 0.65rem 0.5rem;
    border: 0;
  }
}
