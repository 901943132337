@use '../../styles/mixins' as mixins;

$prefix: '--profile';

.profile {
  @include mixins.flex-column();

  position: relative;
  padding: 4.125rem 1rem 1rem;
  border: 0.0625rem solid var(#{$prefix}-border-color);
  background: linear-gradient(
    to bottom,
    var(#{$prefix}-head-background-color) 9.375rem,
    white 9.4375rem,
    white 100%
  );

  &__avatar {
    border: 0.25rem solid var(#{$prefix}-avatar-border-color);
  }

  &__back {
    position: absolute;
    left: 1rem;
    top: 1rem;
    cursor: pointer;
  }

  &__name {
    @include mixins.font-style(2rem, var(#{$prefix}-name-color), 600);

    line-height: 2.75rem;
    text-align: center;
    word-break: break-word;
    white-space: pre-wrap;
  }

  &__bio {
    @include mixins.font-style(1rem, var(#{$prefix}-bio-color));

    margin-top: 0.5rem;
    line-height: 1.5rem;
    text-align: center;
    word-break: break-word;
    white-space: pre-wrap;
  }

  &__url {
    margin-top: 0.5rem;
    text-align: center;
    word-break: break-word;
    white-space: pre-wrap;
  }

  &__action-buttons {
    @include mixins.flex-row();

    margin-top: 1rem;
    gap: 1rem;
  }

  &__social {
    @include mixins.flex-row();

    margin-top: 1rem;
  }

  &__social-link {
    margin: 0 0.5rem;
  }

  &__anonymous {
    @include mixins.flex-column();

    margin-top: 1.5rem;
    gap: 1rem;

    &-text {
      @include mixins.font-style(0.875rem, #787878);
    }
  }

  &__menu {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  &__feeds-wrapper {
    overflow-x: hidden;
  }

  &__preview {
    max-height: calc(100vh - 0.25rem);
  }
}
