@use '../../../styles/mixins' as mixins;

$prefix: '--media-audio';

.player {
  @include mixins.flex-column(flex-start);

  width: calc(100% - 1.5rem);
  padding: 0.75rem;
  gap: 0.5rem;
  background-color: var(#{$prefix}-background-color);
  box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.2);

  &-wrapper {
    @include mixins.flex-row(flex-start, flex-start);
    @include mixins.transform(translateX(-50%));

    position: fixed;
    width: calc(100% - 2rem);
    left: calc(50% - 1rem);
    max-width: 42rem;
    bottom: 1rem;
    z-index: 9999;

    &--hidden {
      display: none;
    }

    &--mobile {
      left: 50%;
    }
  }

  &-title {
    @include mixins.font-style(1rem, #1e1e1e, 600);
    @include mixins.ellipsis();

    line-height: 1.5rem;
    cursor: pointer;
  }

  &-close {
    &#{&} {
      position: absolute;
      right: -0.5rem;
      top: -0.5rem;
    }
  }
}

:global(.rhap_time) {
  @include mixins.font-style(0.875rem, var(#{$prefix}-time-color));
}

:global(.rhap_progress-filled),
:global(.rhap_volume-filled) {
  background-color: var(#{$prefix}-indicator-color);
}

:global(.rhap_progress-bar),
:global(.rhap_volume-bar) {
  background: var(#{$prefix}-progress-color);
}

:global(.rhap_volume-indicator),
:global(.rhap_progress-indicator) {
  display: none;
}

:global(.rhap_rewind-button),
:global(.rhap_forward-button) {
  width: 1.6rem;
  height: 1.6rem;
}
