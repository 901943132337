@use '../../styles/mixins' as mixins;

.right-side {
  @include mixins.flex-column(flex-start, flex-start);
  @include mixins.custom-scrollbar();

  position: sticky;
  width: 18.75rem;
  top: 6rem;
  height: calc(100vh - 7rem);
  gap: 1rem;
  overflow-x: hidden;
}
