@use '../../../styles/mixins' as mixins;

.feed {
  @include mixins.flex-column(initial);

  gap: 1.5rem;
  flex-grow: 1;
  max-width: var(--content-max-width);
  padding-bottom: var(--card-bottom-spacing);

  &__cards {
    @include mixins.flex-column(initial);

    gap: 1.5rem;
  }
}
