@use '../../../../styles/mixins' as mixins;

$prefix: '--card-teaser';

.large-teaser {
  position: relative;

  &__content {
    @include mixins.flex-column(flex-start, flex-start);

    gap: 0.5rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 1.5rem;
    max-width: calc(100% - 3rem);
    background: var(#{$prefix}-background-color);

    &--with-copy {
      padding-bottom: 2.5rem;
    }

    &-source {
      @include mixins.font-style(0.875rem, var(#{$prefix}-text-color));
      @include mixins.ellipsis();

      &-wrapper {
        @include mixins.flex-row(center, flex-start);

        width: 100%;
        gap: 0.5rem;
      }

      line-height: 1.25rem;
    }

    &-title {
      @include mixins.font-style(2.25rem, var(#{$prefix}-text-color), 600);

      line-height: 2.75rem;

      &--mobile {
        font-size: 1.5rem;
        line-height: 2rem;
      }

      &-icon {
        display: inline-flex;
        position: relative;
        top: 0.25rem;
        left: 0.25rem;
      }
    }
  }
}
