@use '../../styles/mixins' as mixins;

$prefix: '--story-tab';

.story-tab-list {
  @include mixins.flex-column(initial, flex-start);

  gap: 1rem;

  &__title {
    @include mixins.font-style(2rem, var(#{$prefix}-headline-color), 600);

    line-height: 2.75rem;
  }

  &__loader {
    @include mixins.flex-column(initial, flex-start);

    gap: 1rem;
  }
}
