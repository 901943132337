@use '../../../styles/mixins' as mixins;

$prefix: '--form';
$link-prefix: '--base-link';

.form {
  @include mixins.flex-column(center, flex-start);

  padding: 0 1rem;
  min-height: 100vh;
  background-color: var(#{$prefix}-outer-background-color);

  &__logo {
    margin: 3.75rem 0;
  }

  &__content {
    @include mixins.flex-column(flex-start);

    gap: 1.5rem;
    width: 100%;
    max-width: 25rem;
    padding: 1.5rem;
    background: var(#{$prefix}-background-color);
    border: 0.0625rem solid var(#{$prefix}-border-color);
    box-sizing: border-box;
    margin-bottom: 2.5rem;

    &--left {
      text-align: left;
    }

    &--center {
      text-align: center;
    }

    &--right {
      text-align: right;
    }

    &-header {
      &-title {
        @include mixins.font-style(1.75rem, var(#{$prefix}-title-color), 600);

        line-height: 2.25rem;

        &--error {
          color: var(#{$prefix}-error-color);
        }
      }

      &-subtitle {
        @include mixins.font-style(0.875rem, var(#{$prefix}-subtitle-color));

        line-height: 1.25rem;
      }
    }

    &-error {
      color: var(#{$prefix}-error-color);
    }

    &-link,
    &-back {
      @include mixins.font-style(0.875rem, var(#{$link-prefix}-text-color));

      display: block;
      width: 100%;
      line-height: 1.5rem;
      text-decoration: none;
      text-align: center;
      cursor: pointer;

      &--highlighted {
        font-weight: 600;
      }

      &:hover {
        color: var(#{$link-prefix}-text-hover-color);
      }
    }

    &-back {
      font-size: 1rem;
      font-weight: 600;
      margin-top: 0.25rem;
    }
  }
}
