@use '../../../../../styles/mixins' as mixins;

.auto-notification {
  @include mixins.flex-column(initial, flex-start);

  gap: 0.5rem;
  margin-top: 1rem;

  &__input {
    width: 100%;
    box-sizing: border-box;
    padding: 1rem;
    border-radius: 0.25rem;
  }
}
