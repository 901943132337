@use '../../../styles/mixins' as mixins;

.free-login {
  @include mixins.flex-column(flex-start);

  gap: 1.5rem;

  &__header {
    @include mixins.flex-column(flex-start);

    gap: 0.5rem;

    &-title {
      @include mixins.font-style(1.75rem, #43445b, 600);
    }

    &-subTitle {
      @include mixins.font-style(0.875rem, #787878);
    }
  }
}
