@use '../../../../styles/mixins' as mixins;

$prefix: '--card';

.details-content {
  @include mixins.flex-column(initial, center);

  padding-bottom: var(--card-bottom-spacing);

  &__author-info {
    margin-bottom: 1.5rem;
  }

  &__tags {
    margin-bottom: 1rem;

    &-wrapper {
      scroll-margin-top: 5rem;
    }
  }

  &__reaction-comment-wrapper {
    @include mixins.flex-column(flex-start, flex-start);

    gap: 0.75rem;
  }

  &__reactions-counter {
    scroll-margin-top: calc(var(--navbar-height));
  }

  &__posted-time {
    @include mixins.font-style(0.875rem, var(#{$prefix}-posted-time-color));

    line-height: 1.25rem;
    margin-bottom: 0.75rem;
    flex-shrink: 0;
  }

  &__action-pane {
    @include mixins.flex-row(center, flex-start);

    width: 100%;
    scroll-margin-top: calc(var(--navbar-height) + 0.5rem);
    margin-top: 0.5rem;
    gap: 1rem;

    &--mobile {
      gap: 1.5rem;
    }
  }
}
