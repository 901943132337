@use '../../../../styles/mixins' as mixins;

$prefix: '--details-block-paragraph';

.paragraph {
  @include mixins.font-style(1rem, var(#{$prefix}-color));

  word-break: break-word;
  white-space: pre-wrap;
  line-height: 1.5rem;

  &--source {
    color: var(--card-content-source-color);
    font-weight: 600;
  }

  p:not(:first-child) {
    margin-top: 1.5rem;
  }
}
