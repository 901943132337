@use '../../../../../styles/mixins' as mixins;

.buttons {
  @include mixins.flex-row(center, flex-end);

  gap: 1.5rem;
  width: 100%;

  &__settings {
    margin-right: auto;
  }
}
