@use '../../styles/mixins' as mixins;

$prefix: '--footer';

.footer {
  @include mixins.flex-column(flex-start, flex-start);

  gap: 1rem;
  margin-top: auto;
  background-color: var(#{$prefix}-background-color);

  &__links {
    @include mixins.flex-column(initial);

    gap: 0.25rem;
    width: 100%;
    border-bottom: 0.0625rem solid var(#{$prefix}-divider-color);
    padding-bottom: 1rem;

    &-item-label {
      font-weight: normal;
      line-height: 1.25rem;
      cursor: pointer;
    }
  }
}
