@use '../../../../styles/mixins' as mixins;

.manage-members {
  @include mixins.flex-column(initial);

  width: calc(100% - 2rem);
  padding: 1rem;
  gap: 0.5rem;

  &__loader {
    @include mixins.flex-column(initial);

    width: calc(100% - 2rem);
    padding: 1rem;
    gap: 1rem;
  }

  &__title {
    @include mixins.font-style(1rem, #1e1e1e, 600);
  }

  &__list {
    @include mixins.flex-column(initial, flex-start);
    @include mixins.custom-scrollbar();

    gap: 0.5rem;

    &-item {
      @include mixins.flex-row(center, flex-start);

      width: 100%;
      gap: 0.5rem;

      &-content {
        @include mixins.flex-row(center, flex-start);

        width: 100%;
        gap: 0.5rem;
        overflow: hidden;
        cursor: pointer;

        &-name {
          @include mixins.ellipsis();
        }
      }

      &-menu {
        margin-left: auto;
      }
    }

    &-no-data {
      @include mixins.flex-column(center, initial);

      gap: 0.5rem;

      &-label {
        @include mixins.font-style(1rem, #787878);
      }
    }
  }
}

.aaa {
  flex-shrink: 0;
}
