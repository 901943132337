:global(.ce-block:only-child [data-empty='true']:before) {
  position: absolute;
  content: attr(data-textholder);
  color: #bebebe;
}

.paragraph {
  outline: 0;
  line-height: 1.25rem;
}
