@use '../../../styles/mixins' as mixins;

$prefix: '--login';

.login-buttons {
  @include mixins.flex-column();

  width: 100%;
  gap: 0.75rem;
}

.loader-wrapper {
  width: 100%;
}

.channels {
  @include mixins.flex-column(center, flex-start);

  width: 100%;
  gap: 0.25rem;

  &-list {
    @include mixins.flex-column(center, flex-start);
    @include mixins.custom-scrollbar();

    width: 100%;
    gap: 0.5rem;
    max-height: 20rem;

    &-item {
      @include mixins.flex-row(center, initial);

      width: calc(100% - 0.5rem);
      border: 0.0625rem solid #ebebeb;
      border-radius: 0.25rem;
      transition: border-color 0.3s;
      box-sizing: border-box;
      padding: 1rem;
      cursor: pointer;

      &-name {
        @include mixins.font-style(1rem, #43445b, 600);
      }

      &-logo {
        max-height: 3rem;
      }

      &:hover {
        border-color: #8f8f8f;
      }
    }
  }

  &-info {
    @include mixins.font-style(0.875rem, #787878);

    width: 100%;
    text-align: center;
    box-shadow: 0 -0.5rem 0.5rem -0.5rem #0000001f;
    padding-top: 0.5rem;
  }
}
