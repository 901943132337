@use '../../../styles/mixins' as mixins;

.tags-picker {
  @include mixins.flex-column(initial);

  width: 100%;
  gap: 0.5rem;

  &__title {
    @include mixins.font-style(1rem, #1e1e1e);
  }

  &__subTitle {
    @include mixins.font-style(0.875rem, #888b90);
  }

  &__tag-name {
    @include mixins.font-style(1rem, #1e1e1e);
    @include mixins.ellipsis(calc(100% - 1rem));

    line-height: 1.25rem;
  }
}
