@use '../../../../styles/mixins' as mixins;

$prefix: '--card-content';

.short-post {
  @include mixins.flex-column(flex-start, flex-start);

  &__scrollable {
    @include mixins.flex-column(flex-start, flex-start);
    @include mixins.custom-scrollbar();

    gap: 1rem;
    padding: 1rem 1rem 0 1rem;
    width: calc(100% - 2rem);
    max-height: calc(100vh - 20.5rem);
    overflow-x: hidden;

    &--mobile {
      max-height: calc(100vh - 25rem);
    }
  }

  &__sourceName,
  &__headline,
  &__subHeadline,
  &__text {
    border: 0;
    padding: 0;
    border-radius: 0;
  }

  &__sourceName,
  &__sourceName:active,
  &__sourceName:focus {
    @include mixins.font-style(0.75rem, var(#{$prefix}-source-color), 500);

    line-height: 1.25rem;
  }

  &__headline,
  &__headline:active,
  &__headline:focus {
    @include mixins.font-style(1.5rem, var(#{$prefix}-headline-color), 600);

    line-height: 2rem;
  }

  &__subHeadline,
  &__subHeadline:active,
  &__subHeadline:focus {
    @include mixins.font-style(1.25rem, var(#{$prefix}-sub-headline-color), 600);

    line-height: 1.5rem;
  }

  &__text,
  &__text:active,
  &__text:focus {
    @include mixins.font-style(1rem, var(#{$prefix}-text-color));

    line-height: 1.5rem;
  }

  &__pane {
    @include mixins.flex-row(flex-start, flex-start);

    padding: 1rem;
    width: calc(100% - 2rem);

    &-fields {
      @include mixins.flex-row(flex-start, flex-start);

      gap: 1rem;
      margin-left: auto;
    }
  }
}
