@use '../../../styles/mixins' as mixins;

$prefix: '--author-info';

.author-info {
  @include mixins.flex-row(center, flex-start);

  gap: 0.5rem;
  flex-grow: 1;
  cursor: pointer;

  &__fields {
    @include mixins.flex-column(flex-start, flex-start);

    gap: 0.35rem;

    &-title {
      @include mixins.font-style(1rem, var(#{$prefix}-title-color), 600);

      word-break: break-word;
    }

    &-subTitle {
      @include mixins.font-style(1rem, var(#{$prefix}-subtitle-color));

      word-break: break-word;
    }
  }
}
