@use '../../../../styles/mixins' as mixins;

$prefix: '--details-block-table';

.wrapper {
  @include mixins.custom-scrollbar(horizontal);

  width: 100%;
  overflow-x: auto;
}

.table {
  @include mixins.font-style(1rem, #1e1e1e);

  width: 100%;
  border-collapse: collapse;

  th {
    font-weight: 600;
    min-width: 7.5rem;
    color: var(#{$prefix}-header-text-color);
    background-color: var(#{$prefix}-header-background-color);
  }

  th,
  td {
    text-align: left;
    border: 0.0625rem solid #ebebeb;
    padding: 0.375rem 0.75rem;
    word-break: break-word;
    white-space: pre-wrap;
  }
}
