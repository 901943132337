@use '../../../../../styles/mixins' as mixins;

.create-template {
  @include mixins.flex-column(initial);

  gap: 1rem;

  &__title {
    @include mixins.font-style(1.25rem, #43445b, 600);
  }

  &__button {
    margin-left: auto;
  }
}
