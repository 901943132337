@use '../../../../styles/mixins' as mixins;

$prefix: '--media-file';

.file-link {
  @include mixins.flex-column(flex-start);

  width: 100%;
  gap: 0.5rem;

  &__description {
    @include mixins.flex-row(center, flex-start);

    gap: 0.5rem;
    width: calc(100% - 2rem);
    padding: 1rem;
    background: var(#{$prefix}-background-color);

    &--pointer {
      cursor: pointer;
    }

    &-type {
      @include mixins.font-style(1rem, var(#{$prefix}-type-color), 600);
      @include mixins.ellipsis();

      white-space: nowrap;
      text-transform: uppercase;
      line-height: 1.5rem;
    }

    &-action {
      @include mixins.font-style(1rem, var(#{$prefix}-name-color), 600);

      line-height: 1.5rem;
      margin-left: auto;
    }
  }

  &__caption {
    @include mixins.font-style(0.875rem, var(#{$prefix}-caption-color));

    line-height: 1.5rem;
    word-break: break-word;
    white-space: pre-wrap;
  }
}
