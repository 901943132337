$prefix: '--details-block-callout';

.callout {
  line-height: 1.5rem;
  padding: 1rem;
  background: var(#{$prefix}-background-color);

  &__input {
    font-size: 1rem;
    line-height: 1.5;
    outline: none;
  }
}
