@use '../../../styles/mixins' as mixins;

$prefix: '--profile';

.post-stats {
  @include mixins.flex-column(initial, flex-start);

  gap: 1rem;
  padding: 1rem;
  width: calc(100% - 2rem);
  background-color: var(#{$prefix}-info-background-color);

  &__title {
    @include mixins.font-style(1rem, var(#{$prefix}-info-value-color), 600);

    line-height: 1.5rem;
  }

  &__field {
    @include mixins.flex-row();

    &-value {
      @include mixins.font-style(1rem, var(#{$prefix}-info-value-color));

      line-height: 1.5rem;
      margin-left: auto;
    }
  }
}
