@use '../../../styles/mixins' as mixins;

$prefix: '--search';
$link-prefix: '--base-link';

.search {
  &__title {
    @include mixins.font-style(2rem, var(#{$prefix}-title-color), 600);

    line-height: 2.75rem;
    word-wrap: break-word;

    &-query {
      color: var(#{$prefix}-query-color);
    }
  }

  &__count {
    @include mixins.font-style(1rem, var(#{$prefix}-count-color));

    line-height: 1.5rem;
    margin-top: 0.25rem;
  }

  &__no-result {
    @include mixins.flex-column();

    gap: 2rem;
    margin: 6.25rem auto 0 auto;
    max-width: 23.75rem;

    &-icon {
      width: 3rem;
      height: 3rem;
    }

    &-text {
      @include mixins.font-style(1rem, var(#{$prefix}-text-color));

      line-height: 1.5rem;
    }

    &-link {
      color: var(#{$link-prefix}-text-color);
      text-decoration: none;

      &:hover {
        color: var(#{$link-prefix}-text-hover-color);
      }
    }
  }

  &__feed {
    @include mixins.flex-column(initial);

    gap: 1.5rem;
    flex-grow: 1;
    max-width: var(--content-max-width);
    padding-bottom: var(--card-bottom-spacing);

    &-cards {
      @include mixins.flex-column(initial);

      gap: 1.5rem;
    }
  }
}
