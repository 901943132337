@use '../../styles/mixins' as mixins;

.sidebar {
  @include mixins.flex-column(initial, flex-start);
  @include mixins.custom-scrollbar();

  position: sticky;
  top: 6rem;
  gap: 1.25rem;
  width: var(--left-sidebar-width);
  height: calc(100vh - 7rem);
  // background-color: #fff;

  &--mobile {
    position: static;
    top: unset;
    height: unset;
  }
}

.logo {
  max-height: 3rem;
}
