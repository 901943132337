@use '../../../../../styles/mixins' as mixins;

.description {
  @include mixins.flex-column(flex-start);

  gap: 1rem;
  margin-top: 1rem;

  &__step {
    @include mixins.flex-column(flex-start);

    width: 100%;

    &-title {
      @include mixins.font-style(1rem, #293033, 600);

      line-height: 1.5rem;
    }

    &-subTitle {
      @include mixins.font-style(0.875rem, #6d767c);

      line-height: 1.25rem;
    }

    &-component {
      @include mixins.flex-column();

      width: 100%;
      gap: 0.5rem;
      margin-top: 0.5rem;
    }

    &-code {
      margin: 0.5rem auto 0 auto;
    }
  }
}

.link {
  margin: 0 auto;
}
