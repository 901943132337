@use '../../styles/mixins' as mixins;

.layout {
  &__page {
    @include mixins.container;
    @include mixins.flex-row(flex-start, flex-start);

    position: relative;
    width: 100%;
    margin: 6rem auto 1rem;
    gap: 2.5rem;
  }

  &__content {
    @include mixins.flex-column(initial);

    width: 100%;
    flex-grow: 1;

    &--mobile {
      width: calc(100% - 2rem);
      padding: 0 1rem;
    }

    &:not(.layout__content--full-width) {
      max-width: var(--content-max-width);
    }

    &--full-width {
      max-width: var(--content-full-max-width);
    }
  }
}
