@use '../../../styles/mixins' as mixins;

.chat {
  @include mixins.flex-column(flex-start);

  gap: 1rem;
  padding-bottom: 12rem;

  &__header {
    @include mixins.font-style(1rem, #43445b, 600);
  }
}
