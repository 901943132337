@use '../../../../styles/mixins' as mixins;

.create-card {
  @include mixins.flex-row();

  position: relative;
  gap: 1rem;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  background-color: #fff;
  border: 0.0625rem solid #ebebeb;

  &__input {
    background-color: #fff;
    padding-left: 0.5rem;
    width: calc(100% - 3.5rem);
  }

  &__button {
    flex-shrink: 0;
    width: 13rem;
  }
}
