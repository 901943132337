@import-normalize;

@import 'styles/swiper';

* {
  font-family: 'Config Font', sans-serif;
}

body {
  overflow-x: hidden;
  background-color: var(--body-background-color);
}

body,
p,
ul {
  margin: 0;
  padding: 0;
}

textarea:focus,
input:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

:root {
  --navbar-height: 4.5rem;
  --container-max-width: 80rem;
  --left-sidebar-width: 16.875rem;
  --content-max-width: 39.375rem;
  --content-full-max-width: 60.625rem;
  --card-spacing: 1.5rem;
  --card-bottom-spacing: 20rem;
  --toastify-toast-width: 25rem;
  --color-danger: #ff5560;
  --color-danger-hover: #fe212f;
}

em-emoji-picker {
  height: calc(var(--card-bottom-spacing) - 1rem);
}

.react-datepicker__portal {
  background-color: var(--modal-overlay-background-color);
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 0.944rem;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 2rem;
  line-height: 2rem;
}

.Toastify__progress-bar-theme--light {
  background: var(--progress-bar-color);
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

.card-disabled,
.card-disabled + .card-actions {
  opacity: 0.5;
  pointer-events: none;
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 0.875rem;
  }
}
