@use '../../../../styles/mixins' as mixins;

$prefix: '--comments-item';
$link-prefix: '--base-link';

.comment {
  @include mixins.flex-row(flex-start);

  gap: 0.75rem;
  position: relative;

  &__avatar--hidden {
    opacity: 0.5;
  }

  &__content {
    @include mixins.flex-column(flex-start, center);

    gap: 0.15rem;
    width: 100%;

    &-header {
      @include mixins.flex-row(flex-start, flex-start);

      gap: 0.5rem;
      width: calc(100% - 4.5rem);

      &--mobile {
        flex-direction: column;
        gap: unset;
      }

      &--hidden {
        opacity: 0.5;
      }
    }

    &-author {
      @include mixins.font-style(1rem, var(#{$prefix}-author-color), 600);

      word-break: break-word;
    }

    &-published {
      @include mixins.font-style(1rem, var(#{$prefix}-published-color));

      flex-shrink: 0;
    }

    &-actions {
      @include mixins.flex-row();

      gap: 0.5rem;

      position: absolute;
      top: 0;
      right: 0;
    }

    &-text {
      @include mixins.font-style(1rem, var(#{$prefix}-text-color));

      line-height: 1.5rem;
      white-space: pre-wrap;
      word-break: break-word;

      &-edited {
        @include mixins.font-style(0.875rem, var(#{$prefix}-edited-color));

        line-height: 1.25rem;
      }

      a {
        text-decoration: none;
        color: var(#{$link-prefix}-text-color);

        &:hover {
          color: var(#{$link-prefix}-text-hover-color);
        }
      }

      &--hidden {
        opacity: 0.5;
      }
    }

    &-links {
      @include mixins.flex-row();

      & > :first-child {
        pointer-events: none;
      }

      gap: 1.5rem;
      margin-top: 0.25rem;
    }
  }
}
