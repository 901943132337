@use '../../../../styles/mixins' as mixins;

.title {
  @include mixins.font-style(1rem, #1e1e1e, 600);
}

.content {
  @include mixins.flex-column(flex-start);

  gap: 0.5rem;
  padding: 0.75rem;

  background-color: #ebebeb;

  &__user {
    @include mixins.flex-row(center, flex-start);

    gap: 0.5rem;

    &-name {
      @include mixins.font-style(1rem, #1e1e1e, 600);
    }
  }

  &__comment {
    @include mixins.font-style(0.875rem, #1e1e1e);

    line-height: 1rem;
  }
}
