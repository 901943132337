@use '../../../styles/mixins' as mixins;

$card-prefix: '--card';

.card {
  @include mixins.flex-column(flex-start, space-between);

  width: 26rem;
  margin: 0 auto;
  border: 0.0625rem solid var(#{$card-prefix}-border-color);
  box-sizing: border-box;
  cursor: pointer;

  &__teaser {
    position: relative;
    height: 12rem;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;

    &-icon {
      @include mixins.position-centered();
    }

    &--overlay {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(41, 48, 51, 0.3);
      }
    }

    &--placeholder {
      background: #43445b;

      &:after,
      &:before {
        @include mixins.position-centered();

        content: '';
        border-radius: 50%;
      }

      &:before {
        height: 7.5rem;
        width: 7.5rem;
        border: 1.625rem solid rgba(255, 255, 255, 0.1);
      }

      &:after {
        width: 4rem;
        height: 4rem;
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }

  &__content {
    @include mixins.flex-column(flex-start, space-between);

    gap: 0.5rem;
    width: calc(100% - 1rem);
    height: 12rem;
    padding: 0.5rem;
  }

  &__posted-time {
    @include mixins.font-style(0.75rem, var(#{$card-prefix}-posted-time-color));

    line-height: 1.125rem;
  }
}
