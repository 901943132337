@use '../../../styles/mixins' as mixins;

$prefix: '--collapse-switcher';

.switcher {
  @include mixins.flex-row(center, flex-start);

  gap: 1rem;
  background-color: #fff;

  &__mode {
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    border: 0.0625rem solid var(#{$prefix}-background-active-color);
    border-radius: 0.25rem;

    &--active {
      background-color: var(#{$prefix}-background-active-color);
    }

    &:nth-child(2) {
      margin-left: -1.25rem;
    }
  }
}
