@use '../../../../styles/mixins' as mixins;

.file-settings {
  @include mixins.flex-column(initial, center);

  gap: 0.5rem;
  margin-top: 1rem;

  &__input {
    @include mixins.font-style(1rem, #1e1e1e);

    line-height: 1.5rem;
    padding: 0.5rem;
  }
}
