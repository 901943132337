@use '../../../../styles/mixins' as mixins;

.user {
  @include mixins.flex-row(center, flex-start);

  padding: 1rem;
  border-radius: 0.5rem;
  gap: 0.5rem;
  border: 0.0625rem solid #ebebeb;
  transition: box-shadow 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;

  &:hover {
    box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.2);
  }

  &__fields {
    @include mixins.flex-column(initial);

    gap: 0.5rem;

    &-name {
      @include mixins.font-style(1rem, #1e1e1e, 600);

      line-height: 1.25rem;
      word-break: break-word;
      white-space: pre-wrap;
    }

    &-position-department {
      @include mixins.font-style(1rem, #888b90);

      line-height: 1.25rem;
    }
  }
}
