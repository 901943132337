@use '../../styles/mixins' as mixins;

$link-prefix: '--base-link';

.push-notifications {
  @include mixins.flex-column(flex-start);

  gap: 1.5rem;

  &__title {
    @include mixins.font-style(2rem, #43445b, 600);

    line-height: 2.5rem;
  }

  &__subTitle {
    @include mixins.font-style(1rem, #787878);

    line-height: 1.5rem;
  }

  &__main {
    width: calc(100% - 2rem);
    padding: 1rem;
    border-radius: 0.25rem;
    // background-color: #f8f8f8;
    background-color: #fff;

    &-switch {
      font-weight: 600;
    }
  }

  &__channel-list {
    @include mixins.flex-column(flex-start);

    width: 100%;

    &-title {
      @include mixins.font-style(1rem, #787878);

      line-height: 1.5rem;
    }

    &-items {
      @include mixins.flex-column(flex-start);

      width: calc(100% - 2rem);
      gap: 1rem;
      padding: 1rem;

      &-switch {
        width: 100%;
      }
    }
  }
}
