@use '../../../styles/mixins' as mixins;

$prefix: '--profile';
$link-prefix: '--base-link';

.personal-info {
  @include mixins.flex-column(initial, flex-start);

  gap: 1rem;
  padding: 1rem;
  width: calc(100% - 2rem);
  background-color: var(#{$prefix}-info-background-color);

  &__title {
    @include mixins.font-style(1rem, var(#{$prefix}-info-value-color), 600);

    line-height: 1.5rem;
  }

  &__field {
    @include mixins.flex-column(flex-start, flex-start);

    &-label {
      @include mixins.font-style(1rem, var(#{$prefix}-info-label-color));
    }

    &-label,
    &-value {
      word-break: break-word;
      white-space: pre-wrap;
      line-height: 1.5rem;
    }

    &-value {
      @include mixins.font-style(1rem, var(#{$prefix}-info-value-color));

      &--email,
      &--phone {
        text-decoration: none;
        color: var(#{$link-prefix}-text-color);

        &:hover {
          color: var(#{$link-prefix}-text-hover-color);
        }
      }
    }
  }
}
