@use '../../../styles/mixins' as mixins;

$prefix: '--error-layout';

.no-access {
  text-align: center;

  &__message {
    @include mixins.font-style(1rem, var(#{$prefix}-message-color));

    max-width: 21.25rem;
    line-height: 1.5rem;
  }

  &__buttons {
    @include mixins.flex-column(center, flex-start);

    gap: 1rem;
  }
}
