@use '../../../../styles/mixins' as mixins;

.cut-card {
  @include mixins.flex-row();

  gap: 0.5rem;
  padding: 1rem;
  border: 0.0625rem solid var(--card-border-color);
  box-sizing: border-box;
  text-align: center;
  transition: box-shadow 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.2);
  }

  &--horizontal {
    @include mixins.flex-column();

    height: 20rem;
  }

  &--collapsed {
    height: 9rem;
  }
}
