.card-actions {
  margin-left: auto;
  position: relative;

  &--horizontal {
    position: absolute;
    right: 0.5rem;
    bottom: 0.35rem;
  }
}
