@use '../../../styles/mixins' as mixins;

$prefix: '--header';
$avatar-prefix: '--avatar';

.navbar {
  @include mixins.flex-row();

  position: fixed;
  height: var(--navbar-height);
  background: var(#{$prefix}-background-color);
  border-bottom: 0.0625rem solid var(#{$prefix}-border-bottom-color);
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  &__container {
    @include mixins.container;
    @include mixins.flex-row(center, space-between);

    width: calc(100% - 2rem);
    padding: 0 1rem;
    gap: 2.5rem;

    &--mobile {
      gap: 1.5rem;
    }
  }

  &__logo-wrapper {
    @include mixins.flex-column(flex-start);

    flex-basis: var(--left-sidebar-width);
  }

  &__logo {
    @include mixins.flex-column(flex-start);

    &-icon {
      max-height: 2.5rem;

      &--mobile {
        max-height: 1.5rem;
      }
    }
  }

  &__search {
    flex-grow: 1;

    max-width: 32.5rem;
  }

  &__links {
    @include mixins.flex-row(center, flex-end);

    gap: 1.5rem;
    flex-basis: 31.625rem;
  }

  &__avatar {
    color: var(#{$avatar-prefix}-background-color);
  }
}
