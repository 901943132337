@use '../../../../styles/mixins' as mixins;

$prefix: '--details-block-video';

.video {
  & &__caption {
    @include mixins.font-style(1rem, var(#{$prefix}-caption-color));

    padding: 0;
    line-height: 1.5rem;
    word-break: break-word;
    white-space: pre-wrap;
  }
}
