@use '../../styles/mixins' as mixins;

$prefix: '--search-input';

.search {
  @include mixins.flex-row();

  position: relative;

  &:hover {
    .search__input {
      background: var(#{$prefix}-background-hover-color);
    }
  }

  &__icon {
    position: absolute;
    left: 0.75rem;
  }

  &__input {
    @include mixins.font-style(1rem, var(#{$prefix}-text-color), 600);

    line-height: 1.5rem;
    border: 0;
    width: 100%;
    padding: 0.75rem 3rem 0.75rem 2.5rem;
    border-radius: 0.25rem;
    transition: background-color 0.2s;
    background-color: var(#{$prefix}-background-color);

    &:focus {
      background: var(#{$prefix}-background-hover-color);
    }

    &::placeholder {
      opacity: 1;
      color: var(#{$prefix}-placeholder-color);
    }
  }

  &__history {
    position: absolute;
    left: 0;
    top: calc(100% + 0.5rem);
    width: 100%;
    background-color: #fff;
    border: 0.0625rem solid #ebebeb;
    border-radius: 0.25rem;
    box-shadow: 0 1.5rem 1.5rem -1rem rgba(0, 0, 0, 0.2);

    &-empty {
      @include mixins.font-style(1rem, #787878);

      line-height: 1.25rem;
      padding: 1rem;
    }

    &-header {
      @include mixins.flex-row(center, space-between);

      padding: 1rem 1rem 0.5rem 1rem;

      &-title {
        @include mixins.font-style(1.25rem, #43445b, 600);

        line-height: 1.75rem;
      }
    }

    &-list {
      @include mixins.custom-scrollbar();

      max-height: 18.75rem;
      overflow: auto;

      &-item {
        @include mixins.flex-row(center, flex-start);

        position: relative;
        gap: 0.5rem;
        height: 2.5rem;
        cursor: pointer;

        &-value {
          @include mixins.font-style(1rem, #1e1e1e);
          @include mixins.ellipsis();

          max-width: calc(100% - 5.5rem);
          margin-left: 1rem;
          line-height: 1.5rem;
        }

        &-actions {
          @include mixins.flex-row(center, flex-start);

          gap: 0.5rem;
          margin: auto 1rem auto auto;
        }

        &:hover {
          background-color: #f8f8f8;
        }
      }
    }
  }

  &__clear {
    position: absolute;
    right: 0.75rem;
  }
}
